import $ from 'jquery'
import { mapActions } from "vuex";
import { eventBus } from "./main";
export default {
    data: function () {
        let self = this;
        return {
            dictionary: {
                en: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'Invalid Email Format';
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + " " + numbers + " " + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('alphaSpaces');
                        },
                        //between: function between(fieldName, n1, n2) {
                        //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        }, 
                     

                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + ' Should be Between ' + n[0] + ' and ' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        },

                        IdentityNumbers: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('IdentityNumbers');
                        },
                        NotAcceptSpecialCharacters: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotAcceptSpecialCharacters');
                        },
                        AcceptOnlyArabicCharWithoutSpcialChar: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('AcceptOnlyArabicCharWithoutSpcialChar');
                        },
                        AcceptOnlyEnglishCharWithoutSpcialChar: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('AcceptOnlyEnglishCharWithoutSpcialChar');
                        },
                        url: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('UrlValidMsg');
                        },
                    }
                },
                ar: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'تنسيق بريد إلكتروني غير صالح';
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + numbers + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('shouldBeCharOnly');
                        },
                        //between: function between(fieldName, n) {
                        //     return self.$t(fieldName) + self.$t('min') + n[0] + self.$t('max') + n[1];
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },
                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + 'يجب ان يكون بين ' + n[0] + 'و' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('Number');
                        },
                        IdentityNumbers: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('IdentityNumbers');
                        },
                        NotAcceptSpecialCharacters: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotAcceptSpecialCharacters');
                        },
                        AcceptOnlyArabicCharWithoutSpcialChar: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('AcceptOnlyArabicCharWithoutSpcialChar');
                        },
                        AcceptOnlyEnglishCharWithoutSpcialChar: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('AcceptOnlyEnglishCharWithoutSpcialChar');
                        },
                        url: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('UrlValidMsg');
                        },
                    }
                }

            },

            showValidMessage: false,
            langPathImg: localStorage.lang == "en" ? "/assets/images/ar.png" : "/assets/images/en.png",

            loader: {},
            successmsg: self.$t('savedSucess'),
            baseUrl: process.env.VUE_APP_APIEndPoint,
            queryUrl: '',
            getoneUrl: '',
            insertUrl: process.env.VUE_APP_APIEndPoint + 'City/Add',
            updateUrl: '',
            deleteUrl: '',
            attDownloadUrl: process.env.VUE_APP_APIEndPoint + 'Attachment/DownloadAttachment/',
            headers: {
                headers:
                {
                    Authorization: ('Bearer ' + self.$store.getters.StateUser?.Token),
                    "lang": self.$i18n.locale,
                    "CLIENT-ID": process.env.VUE_APP_CLIENTID,
                    "Client-Secret": process.env.VUE_APP_ClientSecret
                }
            },
            model: {},
            modelList: [],
            query: {
                Order: {
                    FieldName: "Id",
                    SortType: 2
                },
                Filter: [],
                PageNumber: 1,
                PageSize: process.env.VUE_APP_Default_Page_Size,

            },
            lookquery: {
                Order: {
                    FieldName: "Id",
                    SortType: 2
                },
                Filter: [],
                PageNumber: 1,
                PageSize: 0
            },
            langs: [{ lang: 'en', Name: 'English' }, { lang: 'ar', Name: 'عربي' }],
            isEdit: false,
            currentPage: 1,
            totalPages: 10,
            EditExistTitle: "",
            rememberme: false,
            username: "",
            Filter: {},
            total_pages: 0,

            ViewMode: (self.$route.params?.viewmode == 1 || ((!self.$route.params?.id || self.$route.params?.id == '' || self.$route.params?.id == 0) && !self.CheckPermission('add') && !self.CheckPermission('Wfadd')) || ((self.$route.params?.id && self.$route.params?.id != '' && self.$route.params?.id != 0) && !self.CheckPermission('update') && !self.CheckPermission('WFupdate'))) ? true : false,
            sortcol: "Id",
            sortASC: true,
            EnumWFLevel: {
                Default: 0,
                FirstApproval: 1,
                SecondApproval: 2,
                //ThirdApproval: 3,
                //FourthApproval: 4,
                //FifthApproval: 5,
                Release: 3
            },
           
            // ViewModeVersion: (self.$route.params?.viewmode == 1 || ((!self.$route.params?.id || self.$route.params?.id == '' || self.$route.params?.id == 0) && !self.CheckPermission('add') && !self.CheckPermission('Wfadd')) || ((self.$route.params?.id && self.$route.params?.id != '' && self.$route.params?.id != 0) && !self.CheckPermission('update') && !self.CheckPermission('WFupdate'))) || (this.model?.WFPageLevel == this.EnumWFLevel?.FirstApproval) ? true : false,
            republishId: null,
            ckeditorConfigAr: {
                language: 'ar',
                contentsLangDirection: 'rtl',
                resize_enabled: true,
                colorButton_enableAutomatic: false,
                pasteFromWordRemoveStyles: false,
                allowedContent: true,
                extraPlugins: 'justify',
                removeButtons: 'About'
            },
            ckeditorConfigEn: {
                language: 'en',
                contentsLangDirection: 'ltr',
                resize_enabled: true,
                colorButton_enableAutomatic: false,
                pasteFromWordRemoveStyles: false,
                allowedContent: true,
                extraPlugins: 'justify',
                removeButtons: 'About'
            },
            ckeditorConfig: {
                language: 'en',
                contentsLangDirection: 'ltr',
                resize_enabled: true,
                colorButton_enableAutomatic: false,
                pasteFromWordRemoveStyles: false,
                allowedContent: true,
                extraPlugins: 'justify',
                removeButtons: 'About'
            },
            regexOnlyChar: /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF-a-zA-Z- ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF-a-zA-Z- ]$/,
            regexOnlyCharNumber: /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF-a-zA-Z-0-9- ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF-a-zA-Z-0-9- ]$/
        }
    },
    methods: {
        ...mapActions(["RemoveCurrentUser"]),
        changeLang() {
            //https://vee-validate.logaretm.com/v2/guide/localization.html#localization-api
            this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar";
            localStorage.lang = this.$i18n.locale;
            this.$validator.localize(`${localStorage.lang}`, localStorage.lang == 'en' ? this.dictionary.en : this.dictionary.ar);
            this.langPathImg = localStorage.lang == "en" ? "/assets/images/ar.png" : "/assets/images/en.png"
            this.SetCLang();
        },
        //Accept only Number
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        SetCLang: function () {
            if (localStorage.lang == "en") {
                document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
                document.getElementsByTagName("html")[0].setAttribute("lang", "en")
                document.title = "State Properties General Authority."
            }
            else {
                document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
                document.getElementsByTagName("html")[0].setAttribute("lang", "ar")
                document.title = "الهيئة العامة لعقارات الدولة"
            }
        },
        ConvertToJson: function (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return "";
            }
            return JSON.parse(str);
        },
        load: function () {
            var self = this;
            if (self.queryUrl && self.queryUrl != '') {
                self.ShowLoading();
                this.axios.post(self.queryUrl, self.query, self.headers)
                    .then(function (res) {
                        self.modelList = res.data.Data;
                        self.query.PageNumber = res.data.PageNumber;
                        self.query.PageSize = res.data.PageSize;
                        self.total_rows = res.data.Total;
                        self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                        self.afterLoad();
                        if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                            setTimeout(function () {
                                self.buildPage(res.data.Total, res.data.PageSize);
                            }, 100);
                        }
                    })
                    .catch(function (err) {
                        self.displayError(err);
                        self.afterDisplayError(err);
                        return console.log(err);
                    }).finally(function () {
                        return self.HideLoading();
                    });
            }
        },

        LoadCurrentOne() {
            let self = this;
            if (self.getoneUrl && self.getoneUrl != '' && self.$route.params?.id && self.$route.params?.id != '' && self.$route.params?.id != 0) {
                self.ShowLoading();
                this.axios.get(self.getoneUrl + self.$route.params?.id, self.headers)
                    .then(function (res) {
                        if (res.data.IsSuccess) {
                            self.model = res.data.Data;
                            self.AfterLoadOne();
                            self.isEdit = true;
                            self.editMode();
                        }
                        else {
                            self.notify(localize(res.data.Messages[0].Message), "error");

                        }
                    })
                    .catch(function (err) {
                        self.displayError(err);
                        return console.log(err);
                    }).finally(function () {
                        return self.HideLoading();
                    });
            }
            else {
                self.createMode();
                self.isEdit = false;
            }
        },
        pagingfun: function (pageNo) {
            let self = this;
            self.move(pageNo);
        },
        move: function (n) {
            var self = this;
            switch (n) {
                case -1:
                    if (self.query.PageNumber > 1) {
                        self.query.PageNumber--;
                        self.load();
                    }
                    break;
                case 0:
                    if (self.query.PageNumber < self.total_pages) {
                        self.query.PageNumber++;
                        self.load();
                    }
                    break;
                default:
                    self.query.PageNumber = n;
                    self.load();
                    break;
            }
        },
        buildPage: function (total, pagesize) {
            let self = this;
            jQuery("#paging").pagination({
                items: total,
                itemsOnPage: pagesize,
                cssStyle: 'light-theme',
                onPageClick: self.pagingfun,
                prevText: self.$t('prev'),
                nextText: self.$t('next')
            });
        },
        displayError: function (err) {
            var self = this;
            if (err?.response?.status == "401") {
                return;
            }
            if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "business") {
                //let messages = err?.response?.data?.message;
                var messages = self.ConvertToJson(err?.response?.data?.message);
                if (messages != "") {
                    let errorstr = "";
                    messages.map(x => errorstr += self.$t(x.MemberNames) + ' ' + self.$t(x.ErrorMessage) + "<br/>");
                    self.notify(errorstr, "error")
                }
                else {
                    self.notify(self.$t("ConnectionError"), "error");
                }
            }
            else if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "error") {
                var errorMsg = err?.response?.data?.message;
                self.notify(self.$t(errorMsg[Object.keys(errorMsg)[0]]) + "<br/>" + err?.response?.data?.exCode, "error");
            }
            else if (err.length > 0) {
                for (let i = 0; i < err.length; i++) {
                    let errorstr = "";
                    errorstr += err[i].Message + "<br/>";
                    self.notify(errorstr, "error");
                }
            }
            else {
                self.notify(self.$t("ConnectionError"), "error");
            }
        },
        ShowLoading: function () {
            $("body").addClass("active-loader");
            //let self = this;
            //self.$root.isLoading = true;
            //self.loader = this.$loading.show({
            //        // Optional parameters
            //        container: null,//self.$refs.formContainer,
            //        loader:"bars",
            //        width:170,
            //        height:170,
            //        color:"##007bff"
            //        //canCancel: true,
            //        //onCancel: this.onCancel,
            //    });
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");
            //let self = this;
            //self.$root.isLoading = false;
            //self.loader.hide();
        },
        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        showCreateForm: function () {
            var self = this;
            self.$router.push({ name: self.$route.name + 'Edit' });
        },
        showEditForm: function (Id) {
            var self = this;
            self.$router.push({ name: self.$route.name + 'Edit', params: { id: Id } });
        },
        showViewForm: function (Id) {
            var self = this;
            self.$router.push({ name: self.$route.name + 'Edit', params: { id: Id, viewmode: 1 } });
        },
        showDeleteConfirm: function (n) {
            var self = this;
            self.model = n;
            jQuery('#DeleteConfirmation').modal("show");
            self.closeErrorAlert();

        },
        deleteItem: function () {
            let self = this;
            self.ShowLoading();
            var url = self.deleteUrl.concat(self.model.Id);
            self.preDelete()
            self.axios.delete(url, self.headers)
                .then(function (res) {
                    /*if (res.data.success) {*/
                    self.load();
                    self.model = {};
                    jQuery('#DeleteConfirmation').modal("hide");
                    self.notify(self.$t("DeleteSuccess"), "success");
                    eventBus.$emit("delete-fire");
                    //}
                    //else {
                    //    self.notify(self.$t(res.data.Messages[0].Message), "error");
                    //}
                })
                .catch(function (err) {
                    self.displayError(err);
                    return console.log(err);
                }).finally(function () {
                    return self.HideLoading();
                })
        },
        closeDeletConfirmation: function () {
            jQuery('#DeleteConfirmation').modal("hide");
        },
        ResetFilePreview: function () {

        },
        Insert: function () {
            var self = this;
            self.ShowLoading();
            let url = self.insertUrl
            self.model.Id = 0;
            self.preInsert();
            this.axios.post(url, self.model, self.headers)
                .then(function (res) {

                    if (res.data.IsSuccess) {
                        //self.load();
                        //self.model = {};
                        //Slidepage();
                        self.closeForm();
                        self.notify(self.successmsg, "success");
                        eventBus.$emit("insert-fire");
                    }
                    else {
                        self.notify(self.$t(res.data.Messages[0].Message), "error");
                    }
                })
                .catch(function (err) {
                    self.displayError(err);
                    return console.log(err);
                }).finally(function () {
                    return self.HideLoading();
                })
        },
        Update: function () {
            var self = this;
            self.ShowLoading();
            let url = self.updateUrl
            self.preUpdate();
            this.axios.put(url, self.model, self.headers)
                .then(function (res) {

                    if (res.data.IsSuccess) {
                        //self.load();
                        //self.model = {};
                        //Slidepage();
                        self.closeForm();
                        self.notify(self.successmsg, "success");
                    }
                    else {
                        self.notify(self.$t(res.data.Messages[0].Message), "error");
                    }
                })
                .catch(function (err) {
                    self.displayError(err);
                    return console.log(err);
                }).finally(function () {
                    return self.HideLoading();
                })
        },
        closeForm: function () {
            var self = this;
            //self.model = {};
            //self.isEdit = false;
            //self.clearvalidation();
            //self.closeErrorAlert();
            if (self.$route.matched[0].name != self.$route.meta.main)
              self.$router.push({ name: self.$route.meta.main });
        },
        preInsert: function () { },
        preUpdate: function () { },
        preDelete: function () { },
        prePreview: function () { },
        createMode: function () { },
        editMode: function () { },
        afterLoad: function () { },
        AfterLoadOne() { },

        afterDisplayError: function (err) { },
        clearvalidation() {
            let self = this;
            setTimeout(function () {
                //self.$validator.clean();
                self.$validator.reset();
                self.$validator.errors.clear();
            }, 100);
        },
        customValidate: function () {
            let valid = true;
            let self = this;
            return valid;
        },
        closeErrorAlert: function () {
            $("#errorAlert").hide("fade");
        },

        wfActionConfirmation: function (actionCode) {
            var self = this;
            self.showValidMessage = true;
            let valid = self.customValidate();
            self.$validator.validate().then(function (result) {
                if (!result || !valid) {
                    let detail = $('.form--error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }
                    return;
                }
                $('.form--error').removeClass("form--error");
                self.closeErrorAlert();
                jQuery('#' + actionCode + 'Confirmation').modal("show");
            });
        },

        wfAction: function (actionCode, data) {
            var self = this;
            self.showValidMessage = true;
            let valid = self.customValidate();
            self.$validator.validate().then(function (result) {
                if (!result || !valid) {
                    let detail = $('.form--error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }
                    return;
                }
                $('.form--error').removeClass("form--error");
                self.closeErrorAlert();
                self.ShowLoading();
                var url = self[actionCode + 'Url'];
                if (actionCode == 'reject')
                    self.model.RejectReason = data.rejectReason;
                self.axios.post(url, self.model, self.headers)
                    .then(function (res) {
                        if (res.data.IsSuccess) {
                            self.closeForm();
                            self.notify(self.$t(actionCode + 'Sucess'), "success");
                        }
                        else {
                            self.notify(self.$t(res.data.Messages[0].Message), "error");
                        }
                    })
                    .catch(function (err) {
                        self.displayError(err);
                        return console.log(err);
                    }).finally(function () {
                        jQuery('#' + actionCode + 'Confirmation').modal("hide");
                        return self.HideLoading();
                    })
            });
        },

        ViewVersion: function (id) {
            var self = this;
            self.versionModel = self.model.VersionsList.find(v => v.Id == id);
            jQuery('#VersionView').modal("show");
        },

        HideVersion: function () {

            jQuery('#VersionView').modal("hide");
        },

        republishConfirmation: function (id) {
            var self = this;
            jQuery('#republishConfirmation').modal("show");
            self.republishId = id;
        },

        republish: function (id) {
            var self = this;
            var url = self.republishUrl + id;
            this.axios.get(url, self.headers)
                .then(function (res) {
                    if (res.data.IsSuccess) {
                        self.closeForm();
                        self.notify(self.$t('republishSucess'), "success");
                    }
                    else {
                        self.notify($t(res.data.Messages[0].Message), "error");
                    }
                })
                .catch(function (err) {
                    self.displayError(err);
                    return console.log(err);
                }).finally(function () {
                    jQuery('#VersionView').modal("hide");
                    jQuery('#republishConfirmation').modal("hide");
                    return self.HideLoading();
                });
        },
        save: function (scope = null) {
            var self = this;
            self.showValidMessage = true;
            let valid = self.customValidate();
            var validator = scope == null ? self.$validator.validate() : self.$validator.validate(scope);
            validator.then(function (result) {//// when use html control
                if (!result || !valid) { ////not valid
                    let detail = $('.form--error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }
                    return;
                }
                $('.form--error').removeClass("form--error");
                self.closeErrorAlert();

                if (self.isEdit) {
                    self.Update();
                } else {
                    self.Insert();
                }
                //this.closeForm();
            });
        },
        pageChangeHandler(selectedPage) {
            this.currentPage = selectedPage
        },
        async logout() {
            var self = this;
            self.ShowLoading();
            var url = self.baseUrl + 'Authentication/Logout';
            self.axios.get(url, self.headers)
                .then(function (res) {
                    if (res.data.IsSuccess) {
                        self.model = {};
                        self.notify(self.$t('logout success'), "success");
                    }
                    else {
                        self.notify(self.$t(res.data.Messages[0].Message), "error");
                    }
                })
                .catch(function (err) {
                    self.displayError(err);
                }).finally(function () {
                    self.RemoveCurrentUser();
                    self.$router.push("/login").catch(() => { /* ignore */ });
                    return self.HideLoading();
                })
        },
        loadLookups() {
            return new Promise(function (resolve, reject) {
                resolve("Success!");
            });
        },
        searchReset: function () {
            let self = this;
            self.Filter = {};
            self.query.Filter = [];
            self.query.PageNumber = 1;
            self.load();
        },
        sortMaster: function (evt) {
            let colname = evt.target.attributes["sortcol"].value
            let self = this;
            if (colname == self.sortcol) {
                self.sortASC = !self.sortASC;
            }
            else {
                self.sortASC = true;
            }

            let currntClass = (self.sortASC == true ? "headerSortUp" : "headerSortDown");
            $(evt.target.parentElement).children().each(function () {
                $(this).removeClass("headerSortUp");
                $(this).removeClass("headerSortDown");
            })
            evt.target.classList.add(currntClass);

            self.sortcol = colname;

            self.query.Order.FieldName = self.sortcol;
            self.query.Order.SortType = self.sortASC ? 1 : 2;
            self.load();

        },
        calculate_age(dob) {
            var diff_ms = Date.now() - new Date(dob).getTime();
            var age_dt = new Date(diff_ms);

            return Math.abs(age_dt.getUTCFullYear() - 1970);
        },
        AfterMountedFn() { },
        CheckPermission(actionCode) {

            let self = this;
            return self.$store.getters.CurrentPagePermissions?.filter(x => x.Code == actionCode).length > 0
        }
        ,
          getRecaptchaActivation: function () {
        var self = this;
        //const filterId = pageId;
        const currentPath = this.$route.path;
        self.axios
            .get(process.env.VUE_APP_APIEndPoint + "Menu/PublicGetAll")
            .then((response) => {
                self.recaptchaActivationArr = response.data.Data;
               // console.log('RecaptchaActivation Array:', self.recaptchaActivationArr);
                self.recaptchaActivation = self.recaptchaActivationArr.find(item => item.Url === currentPath)?.RecaptchaActivation;
                self.menuId = self.recaptchaActivationArr.find(item => item.Url === currentPath)?.Id;
                console.log("self.menuId from mixin",self.menuId)
            });
          
    },
    },

    created() {
    },
    mounted() {
        let self = this;
        this.$validator.localize(`${localStorage.lang}`, localStorage.lang == 'en' ? this.dictionary.en : this.dictionary.ar);
        this.SetCLang();
        self.clearvalidation();
        Promise.all([self.loadLookups()]).then(function (dd) {
            self.load();
            self.LoadCurrentOne();
            self.getRecaptchaActivation();
        });


        this.rememberme = localStorage.rememberme
        this.username = localStorage.username
        this.AfterMountedFn();
    },
    computed: {
        isLoggedIn: function () { return this.$store.getters.isAuthenticated },
        ViewModeVersion: function () { return (this.ViewMode || (this.model?.WFPageLevel == this.EnumWFLevel?.FirstApproval)) }
    },

    watch: {
        '$store.getters.StateUser': function () {
            let self = this;
            this.headers = {
                headers:
                {
                    Authorization: ('Bearer ' + self.$store.getters.StateUser?.Token),
                    "lang": self.$i18n.locale,
                    "CLIENT-ID": process.env.VUE_APP_CLIENTID,
                    "Client-Secret": process.env.VUE_APP_ClientSecret
                }
            }
        }
    }
}
