import axios from "axios";

export default {
    resetStatisticsList({ commit }) {
        commit("RESET_STATISTICS_LIST")
    },
    getStatisticsList({ state, commit }, filterQuery) {
        let { PageNumber, PageSize, totalRecord } = state.statisticsList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };
        if (!state.isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "Statistics/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_STATISTICS_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_LOADING_STATE", false) });
        }
    }
}