import axios from "axios";

export default {
    getCareers({ commit, state }) {
        if (!Object.keys(state.careers).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'Career/PublicGetAll/', {
                    "pageNumber": 0,
                    "pageSize": 0,
                })
                .then(response => {
                    commit("GET_CAREERS", {
                        data: response.data.Data,
                    })
                });
    },
    getSocialResponsibility({ commit, state }) {
        if (!Object.keys(state.careers).length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'SocialResponsibility/PublicGetOne/')
                .then(response => {
                    commit("GET_SOCIAL_RESPONSIBILITY", {
                        data: response.data.Data,
                    })
                });
    },
    getIdentityContent({ commit, state }) {
        if (!Object.keys(state.identity.content).length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'IdentityContent/PublicGetOne/')
                .then(response => {
                    commit("GET_IDENTITY_CONTENT", {
                        data: response.data.Data,
                    })
                });
    },
    getIdentityFiles({ commit, state }) {
        if (!Object.keys(state.identity.files).length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'IdentityFiles/PublicGetAll/')
                .then(response => {
                    commit("GET_IDENTITY_FILES", {
                        data: response.data.Data,
                    })
                });
    },
    getIdentity({ dispatch }) {
        dispatch('getIdentityContent');
        dispatch('getIdentityFiles');
    },
    getOpenData({ commit, state }) {
        if (!Object.keys(state.openData).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'OpenData/PublicGetAll/', {
                    "pageNumber": 0,
                    "pageSize": 5,
                })
                .then(response => {
                    commit("GET_OPEN_DATA", {
                        data: response.data.Data,
                    })
                });
    },
    getOpenDataPolicy({ commit, state }) {
        if (!Object.keys(state.openDataPolicy).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'OpenData/PublicGetAll/', {
                    Filter: [{ FieldName: "code", operation: "=", value:"OpenDataPolicy"}],
                    "pageNumber": 1,
                    "pageSize": 5,
                })
                .then(response => {
                    commit("GET_OPENDATA_POLICY", {
                        data: response.data.Data,
                    })
                });
    },
    getOpenDataAdvantages({ commit, state }) {
        if (!Object.keys(state.openDataAdvantage).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'OpenData/PublicGetAll/', {
                    Filter: [{ FieldName: "code", operation: "=", value: "OpenDataAdvantage" }],
                    "pageNumber": 1,
                    "pageSize": 5,
                })
                .then(response => {
                    commit("GET_OPENDATA_ADVANTAGES", {
                        data: response.data.Data,
                    })
                });
    },
    getRelevantAuthority({ state, commit }) {
        let { PageNumber, PageSize, totalRecord } = state.relevantAuthorityList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
        };

        if (!state.RA_isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_RA_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "RelevantAuthority/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_RELEVANT_AUTHORITY", response.data)
                })
                .finally(() => { commit("UPDATE_RA_LOADING_STATE", false) });
        }
    },
    resetOpenDatFilesList({ commit }) {
        commit("RESET_OPENDATA_Files_LIST")
    },
    getOpenDataFiles({ state, commit},filterQuery ) {
        let { PageNumber, PageSize, totalRecord } = state.opendataFilesList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };
       
        if (!state.RA_isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_RA_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "OpenDataFiles/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_OPENDATA_FILES", response.data)
                })
                .finally(() => { commit("UPDATE_RA_LOADING_STATE", false) });
        }
    },
}