export default {
    GET_OPENDATA_POLICY(state, payload) {
        state.openDataPolicy = payload.data.length ? payload.data[0] : {};
    },
    GET_OPENDATA_ADVANTAGES(state, payload) {
        state.openDataAdvantage = payload.data.length ? payload.data[0] : {};
    },

    GET_CAREERS(state, payload) {
        state.careers = payload.data.length ? payload.data[0] : {};
    },
    GET_IDENTITY_CONTENT(state, payload) {
        state.identity.content = payload.data;
    },
    GET_IDENTITY_FILES(state, payload) {
        state.identity.files = payload.data;
    },
    GET_SOCIAL_RESPONSIBILITY(state, payload) {
        state.socialResponsibility = payload.data;
    },
    GET_OPEN_DATA(state, payload) {
        state.openData = payload.data.length ? payload.data[0] : {};
    },
    UPDATE_RA_LOADING_STATE(state, loadingState) {
        return state.RA_isLoading = loadingState;
    },
    
    UPDATE_RELEVANT_AUTHORITY(state, payload) {
        state.relevantAuthorityList.PageNumber = payload.PageNumber;
        state.relevantAuthorityList.totalRecord = payload.Total;

        return state.relevantAuthorityList.data =
            payload.PageNumber == 1
                ? (state.relevantAuthorityList.data = payload.Data)
                : state.relevantAuthorityList.data.concat(payload.Data);
    },
    RESET_OPENDATA_Files_LIST(state) {
        state.opendataFilesList.PageNumber = 0;
        state.opendataFilesList.data = [];
    },
    UPDATE_OPENDATA_FILES(state, payload) {
        state.opendataFilesList.PageNumber = payload.PageNumber;
        state.opendataFilesList.totalRecord = payload.Total;

        return state.opendataFilesList.data =
            payload.PageNumber == 1
            ? (state.opendataFilesList.data = payload.Data)
            : state.opendataFilesList.data.concat(payload.Data);
    },
}