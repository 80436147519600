<template>
    <div>
        <header class="header active">
            <div class="header-search-box">
                <div class="header-search-content">
                    <a class="close-header-search" href="javascript:void(0)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path data-name="Icon material-close" d="M31.5,9.917,29.083,7.5,19.5,17.083,9.917,7.5,7.5,9.917,17.083,19.5,7.5,29.083,9.917,31.5,19.5,21.917,29.083,31.5,31.5,29.083,21.917,19.5Z" transform="translate(-7.5 -7.5)" fill="#fff"></path>
                        </svg>
                    </a>
                    <input class="header-search-input" type="text" :placeholder="$t('SearchWebsite')" v-model="keyword">
                    <button type="submit" id="to-search" @click="search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style="display: block;">
                            <defs>
                                <clipPath id="search-shape" clipPathUnits="userSpaceOnUse">
                                    <path d="M50.576,9.534c-.838-.461-1.716-.943-2.632-1.433-.546-.285-1.1-.58-1.676-.884-.546-.285-1.1-.569-1.667-.864-.858-.441-1.744-.893-2.66-1.345A.036.036,0,0,0,41.91,5c-.9-.461-1.822-.913-2.768-1.364a.538.538,0,0,0-.107-.049c-.945-.471-1.92-.933-2.915-1.395-1.033-.48-2.095-.972-3.177-1.463-.526-.235-1.063-.471-1.6-.717L31.317,0A.1.1,0,0,1,31.3.006l-.016.005C13.723,7.816,2.457,15.1,0,16.8c20.019,12.92,31.316,24.946,31.316,24.946S42.612,29.718,62.631,16.8c-1.228-.853-4.658-3.1-9.99-6.106C51.979,10.31,51.287,9.927,50.576,9.534Z" fill="#47763b" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.309" height="18.309" viewBox="0 0 33.309 33.309">
                            <path data-name="Path 42072" d="M30.889,17.695A13.195,13.195,0,1,1,17.695,4.5,13.195,13.195,0,0,1,30.889,17.695Z" transform="translate(-3 -3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
                            <path data-name="Path 42073" d="M32.15,32.15l-7.175-7.175" transform="translate(-0.962 -0.962)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="container">
                <div class="header--nav">
                    <div class="header--mobile-overlay">
                        <span class="close-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <path data-name="Path 318" d="M23,2.3,20.7,0,11.5,9.2,2.3,0,0,2.3l9.2,9.2L0,20.7,2.3,23l9.2-9.2L20.7,23,23,20.7l-9.2-9.2Z" fill="#FFF"></path>
                            </svg>
                        </span>
                    </div>
                    <div class="top-header">
                        <router-link class="brand-logo" to="/">
                            <img class="white-logo" src="@/assets/images/logo.svg" alt="logo">
                            <img class="colored-logo" src="@/assets/images/logo-colored.svg" alt="logo">
                        </router-link>
                        <a class="header--nav-btn" href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17.948" viewBox="0 0 20 17.948">
                                <path data-name="Path 319" d="M18.718,28.82H1.282a1.282,1.282,0,1,1,0-2.564H18.718a1.282,1.282,0,0,1,0,2.564Z" transform="translate(0 -26.256)" fill="#fff"></path>
                                <path data-name="Path 320" d="M11.538,225.743H1.282a1.282,1.282,0,0,1,0-2.564H11.538a1.282,1.282,0,0,1,0,2.564Z" transform="translate(0 -215.486)" fill="#fff"></path>
                                <path data-name="Path 321" d="M397.179,225.743h-2.051a1.282,1.282,0,1,1,0-2.564h2.051a1.282,1.282,0,0,1,0,2.564Z" transform="translate(-378.461 -215.486)" fill="#fff" opacity="0.5"></path>
                                <path data-name="Path 322" d="M18.718,422.666H1.282a1.282,1.282,0,0,1,0-2.564H18.718a1.282,1.282,0,0,1,0,2.564Z" transform="translate(0 -404.718)" fill="#fff"></path>
                            </svg>
                        </a>
                        <ul class="top-header--icon">

                            <li class="search-icon" type="button">
                                <svg id="Search" xmlns="http://www.w3.org/2000/svg" width="17.302" height="17.306" viewBox="0 0 16.302 16.306">
                                    <path d="M20.611,19.619l-4.534-4.576a6.461,6.461,0,1,0-.981.993l4.5,4.547a.7.7,0,0,0,.985.025A.7.7,0,0,0,20.611,19.619ZM11,16.091A5.1,5.1,0,1,1,14.608,14.6,5.071,5.071,0,0,1,11,16.091Z" transform="translate(-4.5 -4.493)" fill="#fff"></path>
                                </svg>
                            </li>
                            <li class="setting-icon">
                                <!--<svg xmlns="http://www.w3.org/2000/svg" width="17.091" height="17.09" viewBox="0 0 17.091 17.09">
        <path d="M5142.3,625.39a.223.223,0,0,1-.217-.173l-.3-1.277a6.851,6.851,0,0,1-2.151-.9l-1.117.7a.226.226,0,0,1-.278-.032l-1.437-1.436a.224.224,0,0,1-.031-.278l.7-1.116a6.86,6.86,0,0,1-.895-2.153l-1.279-.3a.226.226,0,0,1-.173-.219v-2.031a.225.225,0,0,1,.173-.219l1.279-.3a6.847,6.847,0,0,1,.895-2.153l-.7-1.117a.223.223,0,0,1,.031-.277l1.437-1.436a.223.223,0,0,1,.278-.032l1.117.7a6.843,6.843,0,0,1,2.152-.895l.3-1.277a.224.224,0,0,1,.218-.173h2.031a.223.223,0,0,1,.218.173l.3,1.277a6.852,6.852,0,0,1,2.151.895l1.117-.7a.221.221,0,0,1,.276.032l1.437,1.436a.223.223,0,0,1,.032.277l-.7,1.117a6.816,6.816,0,0,1,.9,2.153l1.277.3a.223.223,0,0,1,.173.219v2.031a.224.224,0,0,1-.173.219l-1.277.3a6.863,6.863,0,0,1-.9,2.153l.7,1.116a.225.225,0,0,1-.031.278l-1.437,1.436a.226.226,0,0,1-.278.032l-1.116-.7a6.875,6.875,0,0,1-2.152.9l-.3,1.277a.225.225,0,0,1-.218.173Zm-2.54-2.8a6.385,6.385,0,0,0,2.257.939.223.223,0,0,1,.173.169l.289,1.242h1.675l.289-1.242a.227.227,0,0,1,.174-.169,6.409,6.409,0,0,0,2.257-.939.222.222,0,0,1,.242,0l1.086.677,1.186-1.184-.678-1.087a.222.222,0,0,1,0-.242,6.413,6.413,0,0,0,.938-2.257.221.221,0,0,1,.169-.173l1.243-.288v-1.677l-1.243-.288a.225.225,0,0,1-.169-.173,6.386,6.386,0,0,0-.938-2.257.223.223,0,0,1,0-.242l.677-1.087-1.186-1.184-1.086.677a.229.229,0,0,1-.243,0,6.387,6.387,0,0,0-2.257-.939.225.225,0,0,1-.173-.169l-.29-1.242h-1.675l-.289,1.242a.223.223,0,0,1-.173.169,6.41,6.41,0,0,0-2.257.939.225.225,0,0,1-.242,0l-1.087-.677-1.185,1.184.678,1.087a.224.224,0,0,1,0,.242,6.386,6.386,0,0,0-.938,2.257.225.225,0,0,1-.169.173l-1.243.288v1.677l1.243.287a.228.228,0,0,1,.169.174,6.4,6.4,0,0,0,.938,2.257.224.224,0,0,1,0,.242l-.678,1.086,1.185,1.186,1.087-.676a.221.221,0,0,1,.119-.034A.227.227,0,0,1,5139.76,622.592Zm-.8-5.4a4.354,4.354,0,1,1,4.354,4.353A4.355,4.355,0,0,1,5138.962,617.2Zm.449,0a3.906,3.906,0,1,0,3.906-3.906A3.906,3.906,0,0,0,5139.411,617.2Z" transform="translate(-5134.771 -608.65)" fill="#fff" stroke="#fff" stroke-width="0.7"></path>
    </svg>-->
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
                                    <g id="accessability_green" data-name="accessability green" transform="translate(-0.25 -0.25)">
                                        <path id="Path_52158" data-name="Path 52158" d="M11,21A10,10,0,1,0,1,11,10,10,0,0,0,11,21Z" fill="none" stroke="#fff" stroke-width="1.5" />
                                        <path id="Path_52159" data-name="Path 52159" d="M13,6a2,2,0,1,1-.586-1.414A2,2,0,0,1,13,6Z" fill="none" stroke="#fff" stroke-width="1.5" />
                                        <path id="Path_52160" data-name="Path 52160" d="M17,9a19.684,19.684,0,0,1-6,1.5A19.684,19.684,0,0,1,5,9m6,2v1.452m0,0a3,3,0,0,0,.476,1.623L14,18m-3-5.548a3,3,0,0,1-.476,1.623L8,18" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5" />
                                    </g>
                                </svg>
                                <div class="settings-dropdown">
                                    <div class="font-settings-color-contrast-wrapper">
                                        <div class="font-settings">
                                            <button class="font-increase">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <path d="M8.5,5.75a.25.25,0,0,1-.25-.25V0H5.75V5.5a.25.25,0,0,1-.25.25H0v2.5H5.5a.25.25,0,0,1,.25.25V14h2.5V8.5a.25.25,0,0,1,.25-.25H14V5.75Zm0,0"></path>
                                                </svg>
                                            </button><span class="current-font-size">A</span>
                                            <button class="font-decrease">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="2.5" viewBox="0 0 14 2.5">
                                                    <path d="M8.5,5.75H0v2.5H14V5.75Zm0,0" transform="translate(0 -5.75)"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="color-contrast">
                                            <button><span class="icon-box"></span><span>{{$t("Contrast")}}</span></button>
                                        </div>
                                    </div>
                                    <div class="read-speaker"></div>
                                </div>
                            </li>
                            <button @click="changeLang" 
                                    class="lang"><strong>{{$i18n.locale=='ar'?'En':'Ar'}}</strong></button>
                            <div class="sec-logo">
                            <!--<img class="white-logo" src="@/assets/images/sec-logo.svg" alt="sec-logo">
                            <img class="colored-logo" src="@/assets/images/sec-logo-colored.svg" alt="sec-logo">-->
                        </div>
                        </ul>
                    </div>
                    <nav class="main-nav">
                        <ul class="nav--bar">
                            <template v-for="item in menuItems">
                                <li class="main-nav--item" v-if="(item.child && item.child.length>0)">
                                    <a class="main-nav--links" href="javascript:void(0)">{{  $i18n.locale == "ar" ? item.TitleAr : item.TitleEn }}</a>
                                    <div class="main-nav-dropdown">
                                        <ul class="drop-menu">
                                            <template v-for="child in item.child">
                                                <li :class="{'has-dropdown': child.child && child.child.length>0}">
                                                    <router-link v-if="child.Url.startsWith('/')" :to="child.Url">{{  $i18n.locale == "ar" ? child.TitleAr : child.TitleEn }}</router-link>
                                                    <a target="_blank" v-else :href="child.Url">{{  $i18n.locale == "ar" ? child.TitleAr : child.TitleEn }}</a>
                                                    <ul class="drop-menu-level2" v-if="(child.child && child.child.length>0)">
                                                        <li v-for="childLevel2 in child.child">
                                                            <router-link v-if="childLevel2.Url.startsWith('/')" :to="childLevel2.Url">{{  $i18n.locale == "ar" ? childLevel2.TitleAr : childLevel2.TitleEn }}</router-link>
                                                            <a target="_blank" v-else :href="childLevel2.Url">{{  $i18n.locale == "ar" ? childLevel2.TitleAr : childLevel2.TitleEn }}</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </li>
                                <li class="main-nav--item" v-else-if="item.Url && item.Url.startsWith('/')">
                                    <router-link class="main-nav--links" :to="item.Url">{{  $i18n.locale == "ar" ? item.TitleAr : item.TitleEn }}</router-link>

                                </li>
                                <li class="main-nav--item" v-else>
                                    <a target="_blank" class="main-nav--links" :href="item.Url">{{  $i18n.locale == "ar" ? item.TitleAr : item.TitleEn }}</a>
                                </li>
                            </template>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>

        <voting></voting>

    </div>
    
</template>

<script>
    import { createNamespacedHelpers } from "vuex";
    const { mapState, mapActions } = createNamespacedHelpers("MenuModule");
    import SwitchLang from '@/components/shared/SwitchLang.vue';
    import Voting from '@/components/shared/Voting.vue'
    //import moment from 'moment'
    import moment from 'moment-hijri'
    export default {
        name: "Header",
        components: {
            SwitchLang,
            Voting
        },
        data() {
            let self = this;
            return {
                cGorgDate: moment().locale(self.$i18n.locale).format('DD MMMM yyyy'),
                CHijriDate: moment().locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('dddd iD iMMMM iYYYY'),
                keyword: undefined

            }
        },
        computed: {
            ...mapState(["menuItems"]),
        },
        methods: {
            ...mapActions(["getMenuItems"]),
            search: function () {
                $(".header-search-box").removeClass("active");
                this.$router.push({ name: 'SearchResults', query: { keyword: this.keyword } });
               // alert(this.keyword);
            },
            changeLang: function () {
                this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar";
                localStorage.lang = this.$i18n.locale;
                this.SetCLang();
            },
            
            SetCLang: function () {
                if (localStorage.lang == "en") {
                    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
                    document.getElementsByTagName("html")[0].setAttribute("lang", "en")
                   // document.title = "State Properties General Authority."
                }
                else {
                    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
                    document.getElementsByTagName("html")[0].setAttribute("lang", "ar")
                   // document.title = "الهيئة العامة لعقارات الدولة"
                }
            },
        },
        mounted() {
            this.getMenuItems();
            this.SetCLang()
            $(window).on("scroll", function () {
                if ($(window).scrollTop() > 0) {
                    $(".header").addClass("scroll-active")
                } else {
                    $(".header").removeClass("scroll-active")
                }
            })

        },
        watch: {
            '$i18n.locale': function () {
                let self = this;
                self.cGorgDate = moment().locale(self.$i18n.locale).format('DD MMMM yyyy');
                self.CHijriDate = moment().locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('dddd iD iMMMM iYYYY');
                //slidersFix();
            },

            $route() {
                let header = document.getElementsByClassName("header");
                header[0].classList.remove("active");
            }
        }
    };
    //// sitting class 

</script>

<style lang="scss">


    .top-header--icon {
        list-style: none;

        li {
            padding: 7px;
            position: relative;

            .settings-dropdown {
                position: absolute;
                top: calc(100% + 30px);
                min-width: 250px;
                max-width: 90vw;
                background: #fff;
                border-radius: 5px;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: all .25s ease-in-out;
                padding: 1.875rem 2.5rem;
                box-shadow: 0px 0px 15px rgba(#000, 0.1);

                @include rtl() {
                    left: 0;
                }

                @include ltr() {
                    right: 0;
                }

                .font-settings-color-contrast-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }

                .font-settings {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .font-settings {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .font-settings {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .font-increase, .font-decrease {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background: transparent;
                    border: 1px solid $gold;
                    padding: 0;
                    cursor: pointer;
                    outline: none;
                    transition: all .25s ease-in-out;

                    svg {
                        width: 12px;
                        height: auto;

                        path {
                            fill: $gold;
                        }
                    }
                }

                .current-font-size {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: none;
                    text-align: center;
                    font-weight: 700;
                    color: $gold;
                    display: inline-flex;
                    justify-content: center;
                    /*align-items: center;*/
                    margin: -5px 1rem 0;
                    font-size: 1.5rem;
                }

                .color-contrast {

                    @include rtl() {
                        padding-right: 1rem;
                        margin-right: 1rem;
                        border-right: 1px solid rgba(112,112,112,.2);
                    }

                    @include ltr() {
                        padding-left: 1rem;
                        margin-left: 1rem;
                        border-left: 1px solid rgba(112,112,112,.2);
                    }

                    button {
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        font-weight: 400;
                        background: none;
                        gap: 0.5rem;
                        border: 0;
                        cursor: pointer;
                        outline: none;
                        color: #202020;
                        transition: all .25s ease-in-out;
                        font-size: var(--txt-link);
                    }

                    .icon-box {
                        display: inline-flex;
                        width: 38px;
                        height: 20px;
                        justify-content: center;
                        align-items: center;
                        background: #fff;
                        border-radius: 30px;
                        border: 2px solid $gold;
                        position: relative;

                        &:after {
                            content: "";
                            width: 10px;
                            height: 10px;
                            background-color: #202020;
                            border-radius: 100%;
                            position: absolute;
                            top: 3px;
                            right: 4px;
                            transition: all .25s ease-in-out;
                        }
                    }
                }
                /*border-radius: 10px;*/
            }
        }

        .setting-icon.active {
            .settings-dropdown {
                top: calc(100% + 10px);
                opacity: 1;
                visibility: visible;
                pointer-events: all;
            }
        }
    }
    .grayscale .top-header--icon li .settings-dropdown .color-contrast .icon-box:after {
        right: 20px;
    }
    
</style>