import functions from '@/functions.js'

export default {
    UPDATE_HERO_SLIDERS(state, payload) {
        state.hero.items = payload.data.sort((a, b) => {
            if (a.Order < b.Order) {
                return -1;
            }
            if (a.Order > b.Order) {
                return 1;
            }
            return 0;
        });;
    },
    UPDATE_LATEST_NEWS(state, payload) {
        state.latestNewsList = payload.data.map(item => {
            item["NewsDateAr"] = functions.formatDateAr(item["NewsDate"]);
            item["NewsDateEn"] = functions.formatDateEn(item["NewsDate"]);
            return item;
        });
    },
    UPDATE_LATEST_STATISTICS(state, payload) {
        state.latestStatisticsList = payload.data;
    },
    UPDATE_INVESTMENT(state, payload) {
        state.investments = payload.data.map(item => functions.addDate(item, "InvestmentDate"));
    },
    UPDATE_TRENDER_ANNOUNCEMENT(state, payload) {
        state.tenderAnnouncements = payload.data.map(item => functions.addDate(item, "Date"));
    },
    UPDATE_SERVICES(state, payload) {
        state.services = payload.data;
    },
    UPDATE_REALESTATES(state, payload) {
        state.realEstates = payload.data;
    }
}