import axios from "axios";

export default {
    getGovernmentalNeeds({ commit, state }) {
        if (!Object.keys(state.governmentalNeeds).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'GovernmentalNeeds/PublicGetAll', {
                    "pageNumber": 0,
                    "pageSize": 0,
                })
                .then(response => {
                    commit("GET_GOVERNMENTAL_NEEDS", {
                        data: response.data.Data,
                    })
                })
    },
    getRealEstateAffairs({ commit, state }) {
        if (!Object.keys(state.realEstateAffairs).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'RealEstateAffairs/PublicGetAll', {
                    "pageNumber": 0,
                    "pageSize": 0,
                })
                .then(response => {
                    commit("GET_REAL_ESTATE_AFFAIRS", {
                        data: response.data.Data,
                    })
                })
    },
    getInvestmentBusiness({ commit, state }) {
        if (!Object.keys(state.investmentBusiness).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'investmentBusiness/PublicGetAll', {
                    "pageNumber": 0,
                    "pageSize": 0,
                })
                .then(response => {
                    commit("GET_INVESTMENT_BUSINESS", {
                        data: response.data.Data,
                    })
                })
    },
    getTenders({ commit, state }) {
        if (!Object.keys(state.tenders).length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'Tenders/PublicGetAll', {
                    "pageNumber": 0,
                    "pageSize": 0,
                })
                .then(response => {
                    commit("GET_TENDERS", {
                        data: response.data.Data,
                    })
                })
    },
    getRegulationsPolicies({ commit, state }) {
        let { PageNumber, PageSize, totalRecord } = state.regulationsPoliciesList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
        };
        if (!state.RP_IsLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_RP_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "RegulationsPolicies/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_REGULATIONS_POLICIES", response.data)
                })
                .finally(() => { commit("UPDATE_RP_LOADING_STATE", false) });
        }
    },
    // START INVESTMENT
    resetInvestmentBusinessList({ commit }) {
        commit("RESET_INVESTMENT_BUSINESS_LIST")
    },
    getInvestmentBusinessList({ state, commit }, filterQuery) {
        let { PageNumber, PageSize, totalRecord } = state.investmentBusinessList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };
        if (!state.IL_IsLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_IL_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "Investment/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_INVESTMENT_BUSINESS_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_IL_LOADING_STATE", false) });
        }
    },
    getInvestmentBusinessDetails({ commit }, id) {
        axios
            .get(process.env.VUE_APP_APIEndPoint + 'Investment/GetInvestmentDetailsById/' + id)
            .then(response => {
                commit("GET_INVESTMENT_BUSINESS_DETAILS", {
                    data: response.data.Data,
                })
            });
    },
    // END INVESTMENT

    // START TENDERS
     resetTendersList({ commit }) {
         commit("RESET_TENDERS_LIST")
    },
    getTendersList({ state, commit }, filterQuery) {
        let { PageNumber, PageSize, totalRecord } = state.tendersList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };
        if (!state.TN_IsLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_TN_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "TenderAnnouncement/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_TENDERS_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_TN_LOADING_STATE", false) });
        }
    },
    getTenderDetails({ commit }, id) {
        axios
            .get(process.env.VUE_APP_APIEndPoint + 'TenderAnnouncement/GetTenderAnnouncementDetailsById/' + id)
            .then(response => {
                commit("GET_TENDER_DETAILS", {
                    data: response.data.Data,
                })
            });
    }
    // END TENDERS
}