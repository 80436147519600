import axios from "axios";

export default {
    resetSearchList({ commit }) {
        commit("RESET_SEARCH_LIST")
    },
    getSearchList({ state, commit }, filterQuery) {
        let { PageNumber, PageSize, totalRecord } = state.searchList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };

        if (!state.isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "search/getall", query)
                .then(response => {
                    commit("UPDATE_SEARCH_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_LOADING_STATE", false) });
        }
    },
  
}