import functions from '@/functions.js'

export default {
    GET_GOVERNMENTAL_NEEDS(state, payload) {
        if (payload.data.length)
            state.governmentalNeeds = payload.data[0];
    },
    GET_REAL_ESTATE_AFFAIRS(state, payload) {
        if (payload.data.length)
            state.realEstateAffairs = payload.data[0];
    },
    GET_INVESTMENT_BUSINESS(state, payload) {
        if (payload.data.length)
            state.investmentBusiness = payload.data[0];
    },
    GET_TENDERS(state, payload) {
        if (payload.data.length)
            state.tenders = payload.data[0];
    },
    GET_REGULATIONS_POLICIES(state, payload) {
        if (payload.data.length)
            state.tenders = payload.data[0];
    },
    UPDATE_RP_LOADING_STATE(state, loadingState) {
        return state.RP_IsLoading = loadingState;
    },
    UPDATE_REGULATIONS_POLICIES(state, payload) {
        state.regulationsPoliciesList.PageNumber = payload.PageNumber;
        state.regulationsPoliciesList.totalRecord = payload.Total;

        return state.regulationsPoliciesList.data =
            payload.PageNumber == 1
                ? (state.regulationsPoliciesList.data = payload.Data)
                : state.regulationsPoliciesList.data.concat(payload.Data);
    },

    // START INVESTMENT
    UPDATE_IL_LOADING_STATE(state, loadingState) {
        return state.IL_IsLoading = loadingState;
    },
    RESET_INVESTMENT_BUSINESS_LIST(state) {
        state.investmentBusinessList.PageNumber = 0;
        state.investmentBusinessList.data = [];
    },
    UPDATE_INVESTMENT_BUSINESS_LIST(state, payload) {
        state.investmentBusinessList.PageNumber = payload.PageNumber;
        state.investmentBusinessList.totalRecord = payload.Total;


        payload.Data.map(item => functions.addDate(item, "InvestmentDate"));
        return state.investmentBusinessList.data =
            payload.PageNumber == 1
                ? (state.investmentBusinessList.data = payload.Data)
                : state.investmentBusinessList.data.concat(payload.Data);
    },
    GET_INVESTMENT_BUSINESS_DETAILS(state, payload) {
        if (payload.data) {
            payload.data = functions.addDate(payload.data, "InvestmentDate")
        }
        state.investmentBusinessDetails = payload.data;
    },
    // END INVESTMENT

    // START TENDERS
    UPDATE_TN_LOADING_STATE(state, loadingState) {
        return state.TN_IsLoading = loadingState;
    },
    RESET_TENDERS_LIST(state) {
        state.tendersList.PageNumber = 0;
        state.tendersList.data = [];
    },
    UPDATE_TENDERS_LIST(state, payload) {
        state.tendersList.PageNumber = payload.PageNumber;
        state.tendersList.totalRecord = payload.Total;


        payload.Data.map(item => functions.addDate(item, "Date"));
        return state.tendersList.data =
            payload.PageNumber == 1
                ? (state.tendersList.data = payload.Data)
                : state.tendersList.data.concat(payload.Data);
    },
    GET_TENDER_DETAILS(state, payload) {
        if (payload.data) {
            payload.data = functions.addDate(payload.data, "Date")
        }
        state.tenderDetails = payload.data;
    }
    // END TENDERS

}