export default () => ({
    governmentalNeeds: {},
    realEstateAffairs: {},
    investmentBusiness: {},
    investmentBusinessList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    investmentBusinessDetails: {},
    IL_IsLoading: false,
    tenders: {},
    tendersList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    tenderDetails: {},
    TN_IsLoading: false,
    regulationsPoliciesList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    RP_IsLoading: false,
});
