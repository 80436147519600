export default () => ({
    imagesGalleryList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    imagesGalleryDetails: {
        AttachmentsAr: [],
        AttachmentsEn: []
        },
    isLoading: false,
});
