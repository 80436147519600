import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_VIDEOS_GALLERY_LIST(state) {
        state.videosGalleryList.PageNumber = 0;
        state.foldersList.data = [];
    },
    UPDATE_VIDEOS_GALLERY_LIST(state, payload) {
        state.videosGalleryList.PageNumber = payload.PageNumber;
        state.videosGalleryList.totalRecord = payload.Total;
        return state.videosGalleryList.data =
            payload.PageNumber == 1
            ? (state.videosGalleryList.data = payload.Data)
            : state.videosGalleryList.data.concat(payload.Data);
    },
    RESET_VIDEOS_GALLERY_DETAILS(state) {
        /*state.foldersList.PageNumber = 0;*/
        state.vidosGalleryDetails.AttachmentAr = [];
        state.vidosGalleryDetails.AttachmentEn = [];
    },
    GET_VIDEOS_GALLERY_DETAILS(state, payload) {
      
        state.videosGalleryDetails = payload.data;
    }
}