import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    GET_PROGRAM_DETAILS(state, payload) {
       
        state.programDetails = payload.data;
    }
}