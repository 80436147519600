import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_REPORTS_LIST(state) {
        state.reportsList.PageNumber = 0;
        state.reportsList.data = [];
    },
    UPDATE_REPORTS_LIST(state, payload) {
        state.reportsList.PageNumber = payload.PageNumber;
        state.reportsList.totalRecord = payload.Total;

        payload.Data.map(item => {
            item["DateAr"] = functions.formatDateAr(item["Date"]);
            item["DateEn"] = functions.formatDateEn(item["Date"]);
            return item;
        });
        return state.reportsList.data =
            payload.PageNumber == 1
            ? (state.reportsList.data = payload.Data)
            : state.reportsList.data.concat(payload.Data);
    }
}