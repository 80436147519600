export default () => ({
   
    HashTages: [],
    resultList: {
        Data: [],
        PageNumber: 0,
        Total: 0,
        PageSize: 20
    },
    HasTagesMeta: [{ id: 0, apiUrl: 'News/GetNewsByHashTages/', detailsUrl: 'NewsDetails' },
        { id: 1, apiUrl: 'Events/GetEventsByHashTages/', detailsUrl: 'EventsDetails' },
        { id: 2, apiUrl: 'Images/GetImageFoldersByHashTages/', detailsUrl: 'PhotoGalleryDetails' },
        { id: 3, apiUrl: 'Videos/GetVideosFoldersByHashTages/', detailsUrl: 'VideoGalleryDetails' },
        { id: 4, apiUrl: 'Folders/GetFoldersByHashTage/', detailsUrl: 'Files' }
    ]
});
