import functions from '@/functions.js'

export default {

    GET_MENU_ITEMS(state, payload) {
        var menuFirstLevel = payload.data.filter(a => a.ParentId == null).
            sort((a, b) => {
                          if (a.Order < b.Order) {
                                return -1;
                            }
                          if (a.Order > b.Order) {
                                return 1;
                            }
                            return 0;
                          });

        for (var i = 0; i < menuFirstLevel.length; i++) {
            var child = payload.data.filter(a => a.ParentId == menuFirstLevel[i].Id);
            menuFirstLevel[i].child = child.sort((a, b) => {
                                            if (a.Order < b.Order) {
                                                return -1;
                                            }
                                            if (a.Order > b.Order) {
                                                return 1;
                                            }
                                            return 0;
                                        });
            
            for (var y = 0; y < child.length; y++) {
                var childLevel2 = payload.data.filter(a => a.ParentId == child[y].Id);
                menuFirstLevel[i].child[y].child = childLevel2.sort((a, b) => {
                    if (a.Order < b.Order) {
                        return -1;
                    }
                    if (a.Order > b.Order) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        payload.data.filter(a => a.Url == undefined).map(a => { a.Url = '#'; });
       
        state.menuItems = menuFirstLevel;
    }
}