import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_FOLDERS_LIST(state) {
        state.foldersList.PageNumber = 0;
        state.foldersList.data = [];
    },
    UPDATE_FOLDERS_LIST(state, payload) {
        state.foldersList.PageNumber = payload.PageNumber;
        state.foldersList.totalRecord = payload.Total;

        //payload.Data.map(item => {
        //    item["DateAr"] = functions.formatDateAr(item["Date"]);
        //    item["DateEn"] = functions.formatDateEn(item["Date"]);
        //    return item;
        //});
        return state.foldersList.data =
            payload.PageNumber == 1
            ? (state.foldersList.data = payload.Data)
            : state.foldersList.data.concat(payload.Data);
    },
    RESET_FOLDERS_DETAILS(state) {
        /*state.foldersList.PageNumber = 0;*/
        state.folderDetails.AttachmentAr = [];
        state.folderDetails.AttachmentEn = [];
    },
    GET_FOLDER_DETAILS(state, payload) {
        if (payload.data) {
            //payload.data["Attachment"] = localStorage.lang == "ar" ? payload.data.AttachmentsAr : payload.data.AttachmentsEn;
            payload.data.AttachmentsAr.map(item => {
                item["Date"] =  functions.formatDateAr(item["Date"]);
             return item;
            });
            payload.data.AttachmentsEn.map(item => {
                item["Date"] = functions.formatDateEn(item["Date"]);
                return item;
            });
            //payload.data["DateAr"] = functions.formatDateAr(payload.data["Date"])
            //payload.data["DateEn"] = functions.formatDateEn(payload.data["Date"])
        }
        state.folderDetails = payload.data;
    }
}