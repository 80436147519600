////import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_STATISTICS_LIST(state) {
        state.statisticsList.PageNumber = 0;
        state.statisticsList.data = [];
    },
    UPDATE_STATISTICS_LIST(state, payload) {
        state.statisticsList.PageNumber = payload.PageNumber;
        state.statisticsList.totalRecord = payload.Total;

        return state.statisticsList.data =
            payload.PageNumber == 1
            ? (state.statisticsList.data = payload.Data)
            : state.statisticsList.data.concat(payload.Data);
    }
}