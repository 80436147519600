import axios from "axios";

export default {

    getVotingData({ state, commit }, id) {
        if (!state.votingData?.length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'Voting/PublicGetAll')
                .then(response => {
                    commit("GET_VOTING_DATA", {
                        data: response.data.Data,
                    })
                });
    },
    submitVoting({ state, commit }, model) {
        axios
            .get(process.env.VUE_APP_APIEndPoint + 'Voting/Voting/' + model.answerId)
            .then(response => {

                var existingVotes = JSON.parse(localStorage.getItem("allVotes"));
                if (existingVotes == null) existingVotes = [];
                var newVoting = {
                    votingId: state.votingData[0].Id
                };

                existingVotes.push(newVoting);
                localStorage.setItem("allVotes", JSON.stringify(existingVotes));

                commit("SUBMIT_VOTING", {
                    data: response.data.Data,
                })
            });
    }
}