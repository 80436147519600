import axios from "axios";

export default {
    
    getProgramDetails({ commit }, id) {
        axios
            .get(process.env.VUE_APP_APIEndPoint + 'Programs/GetProgramsDetailsById/' + id)
            .then(response => {
                commit("GET_PROGRAM_DETAILS", {
                    data: response.data.Data,
                })
            });
    }
}