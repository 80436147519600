import i18n from './i18n'
export default {
    formatDateAr(value) {
        let date = new Date(value);
        const day = date.toLocaleString("default", { day: "2-digit" });
        const month = date.toLocaleString("ar-EG-u-nu-latn", {
            month: "short",
        });
        const year = date.toLocaleString("default", { year: "numeric" });
        //   return  days[date.getDay()] + " - " + day + " - " + month + " - " + year;
        return day + " " + month + " " + year;
    },
    formatDateEn(value) {
        let date = new Date(value);
        const day = date.toLocaleString("default", { day: "2-digit" });
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.toLocaleString("default", { year: "numeric" });
        return day + " " + month + " " + year;
	},
	HijriCalendar(value) {
		var today = new Date(value);
		
		let day = today.getDate();
		let month = today.getMonth();
		let year = today.getFullYear();
		let m = month + 1;
		let y = year;
		if (m < 3) {
			y -= 1;
			m += 12;
		}

		let a = Math.floor(y / 100.);
		let b = 2 - a + Math.floor(a / 4.);
		if (y < 1583) b = 0;
		if (y == 1582) {
			if (m > 10) b = -10;
			if (m == 10) {
				b = 0;
				if (day > 4) b = -10;
			}
		}

		let jd = Math.floor(365.25 * (y + 4716)) + Math.floor(30.6001 * (m + 1)) + day + b - 1524;

		b = 0;
		if (jd > 2299160) {
			a = Math.floor((jd - 1867216.25) / 36524.25);
			b = 1 + a - Math.floor(a / 4.);
		}
		let bb = jd + b + 1524;
		let cc = Math.floor((bb - 122.1) / 365.25);
		let dd = Math.floor(365.25 * cc);
		let ee = Math.floor((bb - dd) / 30.6001);
		day = (bb - dd) - Math.floor(30.6001 * ee);
		month = ee - 1;
		if (ee > 13) {
			cc += 1;
			month = ee - 13;
		}
		year = cc - 4716;

		let wd = this.gmod(jd + 1, 7) + 1;

		let iyear = 10631. / 30.;
		let epochastro = 1948084;
		let epochcivil = 1948085;

		let shift1 = 8.01 / 60.;

		let z = jd - epochastro;
		let cyc = Math.floor(z / 10631.);
		z = z - 10631 * cyc;
		let j = Math.floor((z - shift1) / iyear);
		let iy = 30 * cyc + j;
		z = z - Math.floor(j * iyear + shift1);
		let im = Math.floor((z + 28.5001) / 29.5);
		if (im == 13) im = 12;
		let id = z - Math.floor(29.5001 * im - 29);

		var myRes = new Array(8);

		myRes[0] = day; //calculated day (CE)
		myRes[1] = month - 1; //calculated month (CE)
		myRes[2] = year; //calculated year (CE)
		myRes[3] = jd - 1; //julian day number
		myRes[4] = wd - 1; //weekday number
		myRes[5] = id; //islamic date
		myRes[6] = im - 1; //islamic month
		myRes[7] = iy; //islamic year

		return myRes;
	},
	gmod(n, m) {
		return ((n % m) + m) % m;
	},
	convertToHijri(value, lang) {
		var wdNames = new Array("Ahad", "Ithnin", "Thulatha", "Arbaa", "Khams", "Jumuah", "Sabt");
			
		var iMonthNamesEn = new Array("Muharram", "Safar", "Rabi'ul Awwal", "Rabi'ul Akhir",
			"Jumadal Ula", "Jumadal Akhira", "Rajab", "Sha'ban",
			"Ramadan", "Shawwal", "Dhul Qa'ada", "Dhul Hijja");
		var iMonthNamesAr = new Array('محرم', 'صفر', 'ربيع الأول', 'ربيع الثاني', 'جمادى الأولى', 'جمادى الآخرة', 'رجب', 'شعبان', 'رمضان', 'شوال', 'ذو القعدة', 'ذو الحجة');
		var iDate = this.HijriCalendar(value);
		//var outputIslamicDate = wdNames[iDate[4]] + ", "
		//	+ iDate[5] + " " + iMonthNames[iDate[6]] + " " + iDate[7] + " AH";

		var outputIslamicDate = lang == "Ar" ? (iDate[5]) + " " + iMonthNamesAr[iDate[6]] + " " + iDate[7] + "هـ" : iDate[5] + " " + iMonthNamesEn[iDate[6]] + " " + iDate[7] + "AH";
		return outputIslamicDate;
    },
    addDate(item, dateAttr) {
        item["DisplayDateAr"] = this.formatDateAr(item[dateAttr]);
        item["DisplayDateEn"] = this.formatDateEn(item[dateAttr]);
		item["DisplayHijriDateAr"] = this.convertToHijri(item[dateAttr], "Ar");
		item["DisplayHijriDateEn"] = this.convertToHijri(item[dateAttr], "En");
        return item;
	},
	timeConvert(time) {
		// Check correct time format and split into components
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time.length > 1) { // If time format correct
			time = time.slice(1);  // Remove full string match value
			time.splice(-1);  // Remove Seconds
			time[4] = +time[0] < 12 ? (i18n.locale == 'en' ? 'AM' : ' صباحاً') : (i18n.locale == 'en' ? 'PM' : ' مساءً'); // Set AM/PM
			time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join(''); // return adjusted time or original string
	},
	convertNumbers2English (string) {
		return string.replace(/[\u0660-\u0669]/g, function (c) {
			return c.charCodeAt(0) - 0x0660;
		}).replace(/[\u06f0-\u06f9]/g, function (c) {
			return c.charCodeAt(0) - 0x06f0;
		});
	},
	convertNumbers2Arabic(string) {
		return string.replace(/[0-9]/g, function (c) {
			return String.fromCharCode(c.charCodeAt(0) + 1584);
		})


		//var id = {
		//	0: '٠',
		//	1: '١',
		//	2: '٢',
		//	3: '۳',
		//	4: '٤',
		//	5: '٥',
		//	6: '٦',
		//	7: '۷',
		//	8: '٨',
		//	9: '٩',
  //      };
		//return string.replace(/[0-9]/g, function (w) {
		//	return id[+w]
		//});
	}

}