import axios from "axios";

export default {
    
    getMenuItems({ state, commit }, id) {
        if(!state.menuItems?.length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'Menu/PublicGetAll')
                .then(response => {
                    commit("GET_MENU_ITEMS", {
                        data: response.data.Data,
                    })
                });
    }
}