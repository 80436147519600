export default () => ({
    videosGalleryList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    videosGalleryDetails: {
        AttachmentsAr: [],
        AttachmentsEn: []
        },
    isLoading: false,
});
