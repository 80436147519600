export default () => ({
    contacts: {},
    branchesList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    isLoading: false,
});
