import axios from "axios";

export default {
    resetEventsList({ commit }) {
        commit("RESET_EVENTS_LIST")
    },
    getEventsList({ state, commit }, filterQuery) {
        let { PageNumber, PageSize, totalRecord } = state.eventsList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };
        if (!state.isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "Events/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_EVENTS_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_LOADING_STATE", false) });
        }
    },
    getEventDetails({ commit }, id) {
        axios
            .get(process.env.VUE_APP_APIEndPoint + 'Events/GetEventsDetailsById/' + id)
            .then(response => {
                commit("GET_EVENT_DETAILS", {
                    data: response.data.Data,
                })
            });
    }
}