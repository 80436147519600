import axios from "axios";

export default {
    resetReportsList({ commit }) {
        commit("RESET_REPORTS_LIST")
    },
    getReportsList({ state, commit }) {
        let { PageNumber, PageSize, totalRecord } = state.reportsList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
        };
        if (!state.isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "AnnualReport/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_REPORTS_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_LOADING_STATE", false) });
        }
    }
}