<template>

    <!--<a class="lang-link" href="javascript:void(0)" @click="changeLang" :title="$t('Change Language')">
        <span>{{$t('CLang')}}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path d="M10,8A10,10,0,1,0,20,18,10,10,0,0,0,10,8Zm3.318,14.419-.456.455a.964.964,0,0,0-.249.432c-.061.228-.11.459-.192.68l-.7,1.889a8.058,8.058,0,0,1-1.72.189v-1.1a3.019,3.019,0,0,0-.913-2.067,1.29,1.29,0,0,1-.378-.912V20.691a1.286,1.286,0,0,0-.664-1.128c-.579-.321-1.4-.769-1.968-1.053A5.813,5.813,0,0,1,4.8,17.633L4.769,17.6a4.629,4.629,0,0,1-.728-.836c-.378-.555-.994-1.469-1.395-2.062A8.108,8.108,0,0,1,6.808,10.6l.968.484A.645.645,0,0,0,8.71,10.5v-.456a8.059,8.059,0,0,1,.983-.1l1.141,1.141a.645.645,0,0,1,0,.912l-.189.189-.417.417a.323.323,0,0,0,0,.456l.189.189a.323.323,0,0,1,0,.456l-.323.323a.323.323,0,0,1-.228.094H9.5a.322.322,0,0,0-.225.092l-.4.389a.323.323,0,0,0-.064.375l.629,1.258a.323.323,0,0,1-.288.467H8.928a.322.322,0,0,1-.211-.079l-.374-.325a.646.646,0,0,0-.627-.125L6.459,16.6a.482.482,0,0,0-.063.888l.447.223A2.731,2.731,0,0,0,8.065,18c.424,0,.911,1.1,1.29,1.29h2.692a1.291,1.291,0,0,1,.913.378l.552.552a1.23,1.23,0,0,1,.36.87,1.876,1.876,0,0,1-.553,1.33Zm3.5-3.683a.968.968,0,0,1-.571-.4l-.725-1.087a.967.967,0,0,1,0-1.073l.79-1.185a.967.967,0,0,1,.373-.329L17.2,14.4a7.812,7.812,0,0,1,.786,4.632Z" transform="translate(0 -8)" fill="#202020"></path>
        </svg>
    </a>-->
    <a class="lang-link" href="javascript:void(0)" @click="changeLang" :title="$t('Change Language')">
        <span>{{$t('CLang')}}</span>
        <!--<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17.948" viewBox="0 0 20 17.948">
            <path data-name="Path 319" d="M18.718,28.82H1.282a1.282,1.282,0,1,1,0-2.564H18.718a1.282,1.282,0,0,1,0,2.564Z" transform="translate(0 -26.256)" fill="#fff"></path>
            <path data-name="Path 320" d="M11.538,225.743H1.282a1.282,1.282,0,0,1,0-2.564H11.538a1.282,1.282,0,0,1,0,2.564Z" transform="translate(0 -215.486)" fill="#fff"></path>
            <path data-name="Path 321" d="M397.179,225.743h-2.051a1.282,1.282,0,1,1,0-2.564h2.051a1.282,1.282,0,0,1,0,2.564Z" transform="translate(-378.461 -215.486)" fill="#fff" opacity="0.5"></path>
            <path data-name="Path 322" d="M18.718,422.666H1.282a1.282,1.282,0,0,1,0-2.564H18.718a1.282,1.282,0,0,1,0,2.564Z" transform="translate(0 -404.718)" fill="#fff"></path>
        </svg>-->
    </a>
    
</template>

<script>    

    export default {
        name: 'locale-changer',
        methods: {            
            changeLang() {
                //https://vee-validate.logaretm.com/v2/guide/localization.html#localization-api
                this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar";
                localStorage.lang = this.$i18n.locale;
                this.langPathImg = localStorage.lang == "en" ? "/assets/images/ar.png" : "/assets/images/en.png"
                this.SetCLang();

                slidersFix();
                //location.reload();

            },
            SetCLang: function () {
                if (localStorage.lang == "en") {
                    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
                    document.getElementsByTagName("html")[0].setAttribute("lang", "en")
                    document.title = "Wajib"
                }
                else {
                    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
                    document.getElementsByTagName("html")[0].setAttribute("lang", "ar")
                    document.title = "واجب"
                }
            },
        },
        mounted() {
            let self = this;
            this.SetCLang();
        },
    }
</script>