import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_NEWS_LIST(state) {
        state.newsList.PageNumber = 0;
        state.newsList.data = [];
    },
    UPDATE_NEWS_LIST(state, payload) {
        state.newsList.PageNumber = payload.PageNumber;
        state.newsList.totalRecord = payload.Total;


        payload.Data.map(item => functions.addDate(item, "NewsDate"));
        return state.newsList.data =
            payload.PageNumber == 1
            ? (state.newsList.data = payload.Data)
            : state.newsList.data.concat(payload.Data);
    },
    GET_NEWS_DETAILS(state, payload) {
        if (payload.data) {
            payload.data = functions.addDate(payload.data, "NewsDate")
        }
        state.newsDetails = payload.data;
    }
}