export default () => ({
    careers: {},
    identity: {
        content: {},
        files: []
    },
    openData: {},
    relevantAuthorityList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    opendataFilesList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    socialResponsibility: {},
    openDataPolicy: {},
    openDataAdvantage: {},
    isLoading: false,
    RA_isLoading: false,
});
