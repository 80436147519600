import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_FAQS_LIST(state) {
        state.faqsList.PageNumber = 0;
        state.faqsList.data = [];
    },
    UPDATE_FAQS_LIST(state, payload) {
        state.faqsList.PageNumber = payload.PageNumber;
        state.faqsList.totalRecord = payload.Total;

        return state.faqsList.data =
            payload.PageNumber == 1
            ? (state.faqsList.data = payload.Data)
            : state.faqsList.data.concat(payload.Data);
    }
}