<template>
    <div class="page-tags" v-if="hashTagesIds">
        <div class="container">
            <div class="page-tags--row">
                <h5 class="page-tags--title">
                    {{$t("Hash Tages")}}
                </h5>
                <ul class="page-tags--list">
                    <li class="page-tags--list--item" v-for="item in getHashTagesList"> <router-link class="page-tags--list--link" :to="{ name: 'HashTagesResult', params: { id: item.Id,moduleType:moduleType } }"> {{$i18n.locale == "ar" ? item.NameAr : item.NameEn}}</router-link></li>
                   
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from "vuex";
    const { mapState, mapActions } = createNamespacedHelpers("HashTagesModule");
    export default {
        name: "HashTages",
        props: ['hashTagesIds','moduleType'],
      
        computed: {
            ...mapState(['HashTages']),
            getHashTagesList: function () {
                if (this.hashTagesIds) {
                    var hashTages = this.hashTagesIds.split(',');
                    return this.HashTages?.filter(a => hashTages.includes(a.Id.toString()));
                }
               
            }
        },
        methods: {
            ...mapActions(['getHashTages']),
        },
        created: function () {
            this.getHashTages();
        }
    }
</script>
