<template >
    <section  :class="['rating-sec', directionClass]"  v-if="!successSubmit && ShowRatingSection">
        <div v-for="question in Data" :key="question.Id">
            <div class="rating-title">
                <p>{{$i18n.locale == 'ar' ?  question.QuestionAr : question.QuestionEn}}</p>
                <div class="rating-btns" v-for="answer in question.Answers" :key="answer.Id">
                    <button type="button" class="btn btn-fill" :class="{'selected': selectedAnswerId === answer.Id}"  @click="showReasons(answer.Id)">{{$i18n.locale == 'ar' ? answer.TitleAr :answer.TitleEn}}</button>  
                </div>
                <div>
                    <p style="font-size: 18px;">{{$t("UserRatingCount")}} {{question.UserRatingCount}} {{$t("Visitor")}}</p>
                </div>

            </div>
            <div class="rating-info" v-if="selectedAnswerId">
                
                <ul :class="['unstyled','auth-form-group', {'required-input': true, 'form-error': showValidMessage && errors.has('reason')}]">
                    <li v-for="reason in reasons" :key="reason.Id">
                        <input class="styled-checkbox" :required="requiredReason"  v-validate="requiredReason ? 'required' : ''" name="reason" :id="'reason' + reason.Id" @change="handleReasonChange(reason.Id, $event.target.checked)" type="checkbox" value="value1">
                        <label :for="'reason' + reason.Id">{{$i18n.locale == 'ar' ? reason.TitleAr : reason.TitleEn }}</label>
                        <span v-if="reason.RequiredReason && showValidMessage">{{errors.first('reason')}}</span>
                    </li>
                </ul>
                
                <div class="col-12" v-if="showCommentField">
                    <div :class="['auth-form-group', {'required-input': true, 'form-error': showValidMessage && errors.has('comment')}]">
                        <label class="mb-2">
                            {{$t("Comment")}}
                        </label>
                        <div class="auth-control-wrapper">
                            <textarea style="width: 30%;" class="auth-form-control" v-model="comment" type="text" v-validate="'required'" name="comment" :placeholder="$t('WriteYourMessage')"></textarea>
                        </div>
                        <span v-if="showCommentField && showValidMessage">{{errors.first('comment')}}</span>

                    </div>
                </div>

                <div v-if="showCity" class="col-lg-6">
                    <div :class="['auth-form-group', {'required-input': true, 'form-error': showValidMessage && errors.has('Area')}]">
                        <label class="mb-2">

                            {{$t("Area")}}
                        </label>
                        <div class="auth-control-wrapper">
                            <div class="auth-control-wrapper">
                                <v-autocomplete v-model="cityId" v-validate="isCityRequired ? 'required' : ''" name="Area" :items="Cities" attach solo :placeholder="$t('Select Area')"
                                                class="auth-form-control full-width" :item-text="$i18n.locale == 'ar' ? 'NameAr' : 'NameEn'" item-value="Id"></v-autocomplete>
                            </div>
                        </div>
                        <span v-if="showValidMessage && isCityRequired">{{errors.first('Area')}}</span>
                    </div>
                </div>

                <div class="rating-btns">
                    <button type="button" class="btn btn-fill" @click="save(question.RatingQuestionsId)"> {{$t("Send")}}</button>
                    <button type="button" class="btn btn-o-fill" @click="Cancel()">{{$t("Cancel")}}</button>
                </div>
            </div>
            <div>
                <hr />
            </div>
        </div>
      
    </section>
    <section v-else-if="successSubmit">
        <div class="rating-info submitSec">
            <p>{{$t("SubmittingDone")}}</p>
        </div>
        <div>
            <hr />
        </div>
    </section>

   
</template>

<script>
    import GlobalMixin from '@/GlobalMixin.js';
    import Bowser from 'bowser';
    export default {
        name: "RatingSection",
        mixins: [GlobalMixin],
         props: {
            currentPath: {
                type: String,
                required: true  // Define the prop as required
            }
        },
        data() {
            let self = this;
            return {
                // Paste your JSON data here
                menuId: 0,
                comment: '',
                showCommentField: false,
                selectedAnswerId: null,
                Data: [],
                selectedReasons: [],
                submited:false,
                successSubmit:false,
                Cities: [],
                cityId:null,
                showValidMessage: false,
                model:{},
                warnmesg: self.$t('warnmsg'),
                requiredReason :false,
                UserAgent:'',
                 browserName: '',
                browserVersion: '',
                osName: '',
                ShowRatingSection:false
            }
        },
        methods: {
            loadRatingForm: function () {
                var self = this;
                self.axios
                    .get(process.env.VUE_APP_APIEndPoint + "PageQuestion/PublicGetAll?menuId=" + self.menuId)
                    .then((response) => {
                        self.Data = response.data.Data;
                    });
            },
            getRecaptchaActivation: function () {
                var self = this;
                //const filterId = pageId;
               // const currentPath = this.$route.path;
                //console.log("this.$route.path",this.$route.path)
                console.log("currentPath from page",this.currentPath)
                self.axios
                    .get(process.env.VUE_APP_APIEndPoint + "Menu/PublicGetAll")
                    .then((response) => {
                        self.recaptchaActivationArr = response.data.Data;
                        // console.log('RecaptchaActivation Array:', self.recaptchaActivationArr);
                        self.recaptchaActivation = self.recaptchaActivationArr.find(item => item.Url === this.currentPath)?.RecaptchaActivation;
                        self.menuId = self.recaptchaActivationArr.find(item => item.Url === this.currentPath)?.Id;
                        console.log("self.menuId from methode", self.menuId)
                        self.ShowRatingSection = self.recaptchaActivationArr.find(item => item.Url === this.currentPath)?.ShowRatingSection;
                        console.log("ShowRatingSection", self.ShowRatingSection)
                        self.loadRatingForm();
                    });

            },
            showReasons(answerId) {
                if (this.selectedAnswerId != answerId) {
                    this.selectedReasons = [];
                    console.log("Remove Selected Reason when change answer", this.selectedReasons)
                }
                
                this.showCommentField = false;
                this.selectedAnswerId = answerId;
                console.log("this.selectedAnswerId ", this.selectedAnswerId)
                const selectedAnswer = this.Data
                    .flatMap(question => question.Answers) // Flatten the answers array
                    .find(answer => answer.Id === answerId); // Find the answer with the matching ID
                this.reasons = selectedAnswer ? selectedAnswer.Reasons : [];
                this.reasons.RequiredReason = selectedAnswer.RequiredReason;
                this.requiredReason = selectedAnswer.RequiredReason;
                console.log("this.reasons.RequiredReason", this.reasons.RequiredReason)
                console.log("this.reasons ", this.reasons)
                /* this.showCommentField = this.reasons.some(reason => reason.RequiredComment);*/
                this.showCommentField = this.reasons.some(reason =>
                    this.selectedReasons.includes(reason.Id) && reason.RequiredComment
                );
            },
            handleReasonChange(reasonId, isChecked) {
                if (isChecked) {
                    this.selectedReasons.push(reasonId);
                } else {
                    this.selectedReasons = this.selectedReasons.filter(id => id !== reasonId);
                }

                // Update showCommentField based on selectedReasons
                const selectedReasonsDetails = this.reasons.filter(reason =>
                    this.selectedReasons.includes(reason.Id)
                );
                this.showCommentField = selectedReasonsDetails.some(reason => reason.RequiredComment);
            },
            save: function (RatingQuestionsId) {
                  let self = this;
                  self.showValidMessage = true;
                  self.$validator.validate().then(function (result) {
                      if (!result) { ////not valid
                          let detail = $('.form-error').first()[0];
                          if (detail) {
                              detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                              self.notify(self.warnmesg, "warning");
                          }
                          return;
                      }
                      
                          $('.form-error').removeClass("form-error");
                          self.Submit(RatingQuestionsId);
                                
                  });
            },
            Submit: function (RatingQuestionsId) {
                 var self = this;
                // self.showValidMessage = true;

                //self.submited = true;

                if ((this.showCommentField && !this.comment)) 
                {
                    self.showValidMessage = true;
                    return;
                }


               
                self.model={
                    menuId: self.menuId,
                    ratingQuestionId: RatingQuestionsId,
                    ratingAnswerId: self.selectedAnswerId,
                    //"ipAddress": "string",
                    userAgent: this.UserAgent,
                    cityId: self.cityId,
                    ratingReasonsIds: self.selectedReasons,
                    userComment: self.comment
                };

                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "UserRating/submit", self.model)
                .then(function (res) {
                    self.notify(self.successmsg, "success");
                    self.ClearModel();
                    self.successSubmit = true;
                   // self.scrollToTop();
                   


                });
               

            },
            Cancel: function () {
                 var self = this;
                 self.selectedAnswerId = null;
            },
            ClearModel: function () {
                var self = this;
                 self.showValidMessage = false;
                self.submited = false;
                self.selectedAnswerId = null;
                self.model = {};
              
            },
            loadCity: function () {
                var self = this;
                self.axios
                    .get(process.env.VUE_APP_APIEndPoint + "City/PublicGetAll")
                    .then((response) => {
                        self.Cities = response.data.Data;
                    });
            },
        },
        watch: {
            currentPath: {
                immediate: true,
                handler(newPath, oldPath) {
                    if (newPath !== oldPath) {
                         console.log("New Path:", newPath);
                        console.log("Old Path:", oldPath);
                        this.successSubmit = false;
                        this.selectedAnswerId = false;
                        this.getRecaptchaActivation();
                    }
                }
            }
        },
        created: async function () {
            var self = this;
                await self.getRecaptchaActivation(); // Ensure this is awaited   
            self.loadCity();
            
               
        },
         computed: {
        directionClass() {
            return this.$i18n.locale === 'ar' ? 'd-rtl' : 'd-ltr';

        },

        showCity() {
            // Assuming 'Data' contains your city information
            return this.Data.length > 0 ? this.Data[0].ShowCity : false;
        },
        isCityRequired() {
            // Same assumption for 'IsCityRequired'
            return this.Data.length > 0 ? this.Data[0].IsCityRequired : false;
        }
    },
    //    ,
    mounted() {
       
        // Detect the userAgent using Bowser
        const parser = Bowser.getParser(window.navigator.userAgent);
        const result = parser.getResult();

        // Set the results to the data properties
        this.browserName = result.browser.name;
        this.browserVersion = result.browser.version;
        this.osName = result.os.name;

        // You can also log the full details if needed
        console.log("User-Agent Details:", result);
        this.UserAgent = 'Browser' + ' ' + this.browserName + ' ' + this.browserVersion + ' ' + 'OS Name' + ' ' + this.osName;
        console.log("User-Agent :", this.UserAgent);
    }

}
</script>

<style scoped lang="scss">

    .d-rtl {
        direction: rtl;
        text-align: right;
    }

    .rating-sec {
    }

    .rating-title {
        padding: 20px 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 30px;
        background-image: url(../assets/images/pattern-bg.svg);
        background-size: cover;
    }

        .rating-title p {
            font-size: 24px;
            font-weight: bold;
        }

    .rating-btns {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
    }

    .rating-sec .btn {
        text-align: center;
        padding: 10px 25px;
        border-radius: 40px;
        min-width: 80px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
    }

    .btn-fill {
        color: #fff;
        background-color: #B99D84;
        border: 1px solid #B99D84;
    }

    .btn-fill.selected {
        background-color: #8A6F5A; /* Change to your desired color */
        border-color: #8A6F5A;
    }

    .btn-o-fill {
        color: #000;
        background-color: #fff;
        border: 1px solid #000;
    }

    .rating-info {
        padding: 50px;
        color: #fff;
       /* background-color: #47763B;*/
    }

     .submitSec {
        text-align: center;
    }

    .rating-info p {
        font-size: 30px;
        margin-top: 0;
    }

        .rating-info .btn-o-fill {
            border-color: #fff;
        }

    .btn-fill:hover {
        background-color: #dab28e;
        border-color: #dab28e;
    }

    .btn-o-fill:hover {
        background-color: #e4e4e4;
        border-color: #bfbdbd;
    }

    .unstyled {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-bottom: 50px;
    }

        .unstyled li {
            margin: 20px 0;
        }

    .styled-checkbox {
        position: absolute;
        opacity: 0;
    }

        .styled-checkbox + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            font-size: 18px;
        }

            .styled-checkbox + label:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                background: white;
            }

        .styled-checkbox:checked + label:before {
            background: #b99d84;
        }

        .styled-checkbox:disabled + label {
            color: #b8b8b8;
            cursor: auto;
        }

            .styled-checkbox:disabled + label:before {
                box-shadow: none;
                background: #ddd;
            }

        .styled-checkbox:checked + label:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 9px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
            transform: rotate(45deg);
        }

    .d-rtl .styled-checkbox + label:before {
        margin-right: 0px;
        margin-left: 10px;
    }

    .d-rtl .styled-checkbox:checked + label:after {
        left: auto;
        right: 13px;
    }

    @media(max-width: 480px) {
        .rating-title {
            padding: 20px;
            gap: 0;
        }

            .rating-title p {
                font-size: 20px;
            }

        .rating-info {
            padding: 20px;
        }

            .rating-info p {
                font-size: 22px;
            }

        .unstyled {
            margin-bottom: 30px;
        }
    }

 
</style>

