import axios from "axios";

export default {
    resetFoldersList({ commit }) {
        commit("RESET_FOLDERS_LIST")
    },
    getFoldersList({ state, commit }, filterQuery) {
        let { PageNumber, PageSize, totalRecord } = state.foldersList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };
        if (!state.isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "Folders/PublicGetAll", query)
                .then(response => {
                    commit("UPDATE_FOLDERS_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_LOADING_STATE", false) });
        }
    },
    getFolderDetails({ commit }, getDto) {
        let query = {
            PageNumber: 1,
            PageSize:100,
            Filter: getDto.filterQuery
        };
        axios
            .post(process.env.VUE_APP_APIEndPoint + 'Folders/GetFoldersDetailsById/' + getDto.id, query,getDto.isArabic)
            .then(response => {
                commit("GET_FOLDER_DETAILS", {
                    data: response.data.Data,
                    isArabic: getDto.isArabic
                })
            });
    }
}