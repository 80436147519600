export default () => ({
    hero: {
        items: [],
    },
    latestNewsList: [],
    latestStatisticsList: [],
    investments: [],
    tenderAnnouncements: [],
    services: [],
    realEstates:[]
});
