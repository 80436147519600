import functions from '@/functions.js'

export default {

    GET_VOTING_DATA(state, payload) {

        state.isVotingOpen = false;
        state.votingData = payload.data;

        if (state.votingData.length > 0) {
            state.isVotingDataAvailable = true;

            state.isVotingCurrentlyOpen = payload.data[0].IsVotingCurrentlyOpen;

            if (state.isVotingCurrentlyOpen) {
                state.displayResults = false;
            }
            else {
                state.displayResults = true;
            }

            /////////////////   Check Local Storage   ////////////////////
            var isVotingDone = false;

            var existingVotes = JSON.parse(localStorage.getItem("allVotes"));

            if (existingVotes != null) {
                if (existingVotes.find(v => v.votingId == payload.data[0].Id)) {
                    isVotingDone = true;
                }
            }
            if (isVotingDone) {
                state.isVotingCurrentlyOpen = false;
                state.displayResults = true;
            }
            ////////////////////////////////////////////////////////////

            state.votingAnswers = payload.data[0].Answers;

            var total = 0;

            state.votingAnswers.forEach(ans => {
                total += ans.Count;
            });

            state.totalVotes = total;
        }
        else {
            state.isVotingDataAvailable = false;
        }
    },
    SUBMIT_VOTING(state, payload) {

        state.isVotingCurrentlyOpen = false;
        state.displayResults = true;

        state.votingAnswers = payload.data.Answers;

        var total = 0;

        state.votingAnswers.forEach(ans => {
            total += ans.Count;
        });

        state.totalVotes = total;
    }
}