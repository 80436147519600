import Vue from "vue";
import Vuex from "vuex";
import HomeModule from "./HomeModule/index";
import NewsModule from "./NewsModule/index";
import ReportsModule from "./ReportsModule/index";
import StatisticsModule from "./StatisticsModule/index";
import EventsModule from "./EventsModule/index";
import RulesModule from "./RulesModule/index";
import ActionsModule from "./ActionsModule/index"
import FoldersModule from "./FoldersModule/index"
import ImagesModule from "./ImagesModule/index"
import VideosModule from "./VideosModule/index"
import FAQsModule from "./FAQsModule/index";
import ProgramModule from "./ProgramModule/index";
import MenuModule from "./MenuModule/index";
import HashTagesModule from "./HashTagesModule/index";
import ServicesModule from "./ServicesModule/index";
import AboutModule from "./AboutModule/index";
import PartnerServices from "./PartnerServices/index";
import GeneralModule from "./GeneralModule/index";
import SearchModule from "./SearchModule/index";
import BranchesModule from "./BranchesModule/index";
import VotingModule from "./VotingModule/index";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        HomeModule,
        NewsModule,
        ReportsModule,
        StatisticsModule,
        EventsModule,
        RulesModule,
        ActionsModule,
        FoldersModule,
        ImagesModule,
        VideosModule,
        FAQsModule,
        ProgramModule,
        MenuModule,
        HashTagesModule,
        ServicesModule,
        AboutModule,
        PartnerServices,
        GeneralModule,
        SearchModule,
        BranchesModule,
        VotingModule,
    }
});

