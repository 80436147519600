<template>
    <div class="breadcrumb">
        <ul>
            <li>
                <router-link :to="'/'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18.95" viewBox="0 0 19 18.95">
                        <path d="M18.653,19.475H12.481a.809.809,0,0,1-.807-.807V14.836a1.735,1.735,0,0,0-3.469,0v3.832a.809.809,0,0,1-.807.807H1.307A.809.809,0,0,1,.5,18.668V8.341a2.254,2.254,0,0,1,.928-1.815L9.536.676a.787.787,0,0,1,.928,0l8.108,5.849A2.254,2.254,0,0,1,19.5,8.341V18.708a.87.87,0,0,1-.847.766ZM13.288,17.9h4.6V8.341a.69.69,0,0,0-.282-.524L9.98,2.29,2.356,7.776a.69.69,0,0,0-.282.524v9.561h4.6V14.836a3.308,3.308,0,0,1,6.616,0Z" transform="translate(-0.5 -0.525)" fill="#fff" opacity="0.5"></path>
                    </svg>
                </router-link>
            </li>
            <li v-for="(link, index) in links" v-if="Object.keys(link).length">
                <router-link v-if="index != links.length - 1" :to="link.Url=='#'?'#':link.Url">{{link.Title[$i18n.locale]}}</router-link>
                <span v-else>{{link.Title[$i18n.locale]}}</span>
            </li>
            <!--<li><span>???? ?? ??????</span></li>-->
        </ul>
    </div>
    
    <!--<div class="inner-hero">
        <div class="inner-hero--content">
            <div class="container">
                <p class="inner-hero--sub-title">
                    {{headerLine1}}
                </p>
                <h1 class="inner-hero--title">
                    {{headerLine2}}
                </h1>
            </div>
            <div class="inner-hero--footer">
                <div class="container">
                    <div class="inner-hero--footer--row">
                        <ol class="inner-hero--breadcrumb">
                            <li class="inner-hero--breadcrumb--item">
                                <router-link :to="'/'"></router-link>
                            </li>
                            <li class="inner-hero--breadcrumb--item active" v-for="link in links">
                                <router-link :to="link.Url=='#'?'#':link.Url">{{link.Title}}</router-link>
                            </li>
                        </ol>
                        <div class="inner-hero--actions">
                            <button class="print-btn" onclick="window.print();" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.73" viewBox="0 0 18 16.73">
                                    <path data-name="Path 42098" d="M16.6,22.138H14.691V18.665a.6.6,0,0,0-.6-.6H3.909a.6.6,0,0,0-.6.6v3.473H1.4a1.4,1.4,0,0,0-1.4,1.4v6.036a1.4,1.4,0,0,0,1.4,1.4H3.309v3.221a.6.6,0,0,0,.6.6H14.091a.6.6,0,0,0,.6-.6V30.974H16.6a1.4,1.4,0,0,0,1.4-1.4V23.538A1.4,1.4,0,0,0,16.6,22.138ZM4.509,19.265h8.982v2.873H4.509Zm8.982,14.33H4.509V28.686h8.982C13.491,28.835,13.491,33.482,13.491,33.595Zm.6-7.934H12.564a.6.6,0,1,1,0-1.2h1.527a.6.6,0,1,1,0,1.2Z" transform="translate(0 -18.065)" fill="#fff"></path>
                                </svg>
                            </button>
                            <div class="share-widget inner-hero-style">
                                <a class="share-icon" href="javascript:;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.72" height="25.965" viewBox="0 0 22.72 25.965">
                                        <path data-name="Icon awesome-share-alt" d="M17.851,16.228a4.847,4.847,0,0,0-3.032,1.06l-5.2-3.248a4.9,4.9,0,0,0,0-2.114l5.2-3.248A4.86,4.86,0,1,0,13.1,5.925L7.9,9.174a4.869,4.869,0,1,0,0,7.618l5.2,3.248a4.869,4.869,0,1,0,4.753-3.812Z" fill="#fff"></path>
                                    </svg><span class="share-txt">{{ $t('Share')}}</span>
                                </a>
                                <ul class="share-list">
                                    <li>
                                        <a :href="'mailto:?subject='+title +'&body='+url" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.554" height="14.843" viewBox="0 0 18.554 14.843">
                                                <path data-name="Icon material-email" d="M19.7,6H4.855A1.853,1.853,0,0,0,3.009,7.855L3,18.988a1.861,1.861,0,0,0,1.855,1.855H19.7a1.861,1.861,0,0,0,1.855-1.855V7.855A1.861,1.861,0,0,0,19.7,6Zm0,3.711-7.422,4.638L4.855,9.711V7.855l7.422,4.638L19.7,7.855Z" transform="translate(-3 -6)" fill="#fff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'https://api.whatsapp.com/send?text=' + url" target="_blank" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.843" height="14.843" viewBox="0 0 14.843 14.843">
                                                <path data-name="Icon ionic-logo-whatsapp" d="M9.806,2.25A7.258,7.258,0,0,0,2.519,9.48a7.155,7.155,0,0,0,1.046,3.733L2.25,17.093l4.034-1.282A7.3,7.3,0,0,0,17.093,9.48,7.258,7.258,0,0,0,9.806,2.25Zm3.623,9.976a1.882,1.882,0,0,1-1.289.831c-.342.018-.351.265-2.214-.544a7.6,7.6,0,0,1-3.072-2.9,3.573,3.573,0,0,1-.687-1.937,2.065,2.065,0,0,1,.709-1.515.714.714,0,0,1,.5-.212c.147,0,.242,0,.35,0S8,5.921,8.143,6.3s.479,1.3.522,1.391a.338.338,0,0,1,0,.324,1.265,1.265,0,0,1-.2.3c-.1.1-.2.233-.291.313s-.2.185-.1.373a5.576,5.576,0,0,0,.987,1.316,5.084,5.084,0,0,0,1.461.971c.183.1.292.088.405-.031s.485-.522.616-.7.254-.145.422-.077,1.065.549,1.248.648.3.15.348.229A1.53,1.53,0,0,1,13.429,12.226Z" transform="translate(-2.25 -2.25)" fill="#fff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+ url" target="_blank" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.843" height="14.843" viewBox="0 0 14.843 14.843">
                                                <path data-name="Icon awesome-linkedin-in" d="M3.322,14.843H.245V4.934H3.322ZM1.782,3.582a1.791,1.791,0,1,1,1.782-1.8A1.8,1.8,0,0,1,1.782,3.582ZM14.84,14.843H11.769V10.019c0-1.15-.023-2.624-1.6-2.624-1.6,0-1.845,1.249-1.845,2.541v4.907H5.25V4.934H8.2V6.285h.043a3.233,3.233,0,0,1,2.912-1.6c3.114,0,3.687,2.051,3.687,4.715v5.444Z" transform="translate(0 -0.001)" fill="#fff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'http://twitter.com/share?text=' + title + '&url=' +url" target="_blank" rel="noreferrer noopener">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.056" height="13.14" viewBox="0 0 16.056 13.14">
                                                <path data-name="Path 42070" d="M16.349,5.223a.34.34,0,0,0,.118-.34.347.347,0,0,0-.257-.264l-.548-.139a.34.34,0,0,1-.229-.187.375.375,0,0,1,0-.3l.305-.618a.34.34,0,0,0-.042-.368.361.361,0,0,0-.361-.118l-1.388.389a.326.326,0,0,1-.305-.056A3.47,3.47,0,0,0,8.09,6v.25a.167.167,0,0,1-.153.174c-1.95.229-3.817-.791-5.83-3.082a.347.347,0,0,0-.354-.1.375.375,0,0,0-.2.312,5.3,5.3,0,0,0,.319,3.415.174.174,0,0,1-.18.25L.913,7.021a.34.34,0,0,0-.3.1.34.34,0,0,0-.1.305,3.582,3.582,0,0,0,1.645,2.644.18.18,0,0,1,.1.16.167.167,0,0,1-.1.153l-.368.146a.368.368,0,0,0-.194.2.354.354,0,0,0,0,.278,3.026,3.026,0,0,0,2.221,1.721.18.18,0,0,1,.118.167.174.174,0,0,1-.118.16,7.392,7.392,0,0,1-2.978.576.361.361,0,0,0-.416.278A.354.354,0,0,0,.7,14.33a13.882,13.882,0,0,0,5.671,1.34,8.669,8.669,0,0,0,4.859-1.388,8.641,8.641,0,0,0,3.852-7.26V6.438a.4.4,0,0,1,.125-.264Z" transform="translate(-0.42 -2.53)" fill="#fff"></path>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</template>
<script>
    export default {
        name: "Breadcrumb",
        props: ['links'],
    }
</script>
