<template>
    <section class="inner-hero">
        <div class="container">
            <h1 class="inner-hero__title" v-if="Object.keys(title).length">{{title[$i18n.locale]}}</h1>
            <div class="inner-hero__wrapper">
                <breadcrumb :links="links"></breadcrumb>
                <div class="inner-hero__actions">
                    <span class="inner-hero__actions--btns">
                        <button class="print-btn" onclick="window.print()" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.001" height="16.729" viewBox="0 0 18.001 16.729">
                                <path d="M12401.909,12318.73a.6.6,0,0,1-.6-.6v-3.223h-1.906a1.4,1.4,0,0,1-1.4-1.4v-6.036a1.4,1.4,0,0,1,1.4-1.4h1.906v-3.474a.6.6,0,0,1,.6-.6h10.181a.6.6,0,0,1,.6.6v3.474h1.91a1.4,1.4,0,0,1,1.4,1.4v6.036a1.4,1.4,0,0,1-1.4,1.4h-1.91v3.223a.6.6,0,0,1-.6.6Zm.6-1.2h8.983v-4.908h-8.983Zm7.457-8.532a.6.6,0,0,0,.6.6h1.527a.6.6,0,1,0,0-1.2h-1.527A.6.6,0,0,0,12409.965,12309Zm-7.457-2.923h8.983v-2.873h-8.983Zm2.182,10.782a.533.533,0,0,1,0-1.066h4.62a.533.533,0,1,1,0,1.066Zm0-2.16a.534.534,0,0,1,0-1.068h4.62a.534.534,0,1,1,0,1.068Z" transform="translate(-12397.999 -12302.001)" fill="#fff"></path>
                            </svg>
                        </button>
                    </span>
                    <span class="inner-hero__actions--btns">
                        <button class="share-btn card-icon" type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19.2" viewBox="0 0 18 19.2">
                                <path d="M30.625,12.45a3.362,3.362,0,0,0-2.706,1.378L22.6,11.1a3.184,3.184,0,0,0-.1-2.2l5.567-3.35a3.357,3.357,0,1,0-.817-2.176,3.344,3.344,0,0,0,.235,1.213L21.9,7.948a3.369,3.369,0,1,0,.212,4.172l5.3,2.715a3.329,3.329,0,0,0-.165.99,3.375,3.375,0,1,0,3.375-3.375Z" transform="translate(-16)" fill="#fff"></path>
                            </svg>
                            <ul class="spga-share-list">
                                <li>
                                    <a :href="'mailto:?subject=' + title[$i18n.locale] + '&body=' +url" target="_blank" rel="noreferrer nofollow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.554" height="14.843" viewBox="0 0 18.554 14.843">
                                            <path data-name="Icon material-email" d="M19.7,6H4.855A1.853,1.853,0,0,0,3.009,7.855L3,18.988a1.861,1.861,0,0,0,1.855,1.855H19.7a1.861,1.861,0,0,0,1.855-1.855V7.855A1.861,1.861,0,0,0,19.7,6Zm0,3.711-7.422,4.638L4.855,9.711V7.855l7.422,4.638L19.7,7.855Z" transform="translate(-3 -6)" fill="#fff"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a :href="'https://api.whatsapp.com/send?text=' +url" target="_blank" rel="noreferrer nofollow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.843" height="14.843" viewBox="0 0 14.843 14.843">
                                            <path data-name="Icon ionic-logo-whatsapp" d="M9.806,2.25A7.258,7.258,0,0,0,2.519,9.48a7.155,7.155,0,0,0,1.046,3.733L2.25,17.093l4.034-1.282A7.3,7.3,0,0,0,17.093,9.48,7.258,7.258,0,0,0,9.806,2.25Zm3.623,9.976a1.882,1.882,0,0,1-1.289.831c-.342.018-.351.265-2.214-.544a7.6,7.6,0,0,1-3.072-2.9,3.573,3.573,0,0,1-.687-1.937,2.065,2.065,0,0,1,.709-1.515.714.714,0,0,1,.5-.212c.147,0,.242,0,.35,0S8,5.921,8.143,6.3s.479,1.3.522,1.391a.338.338,0,0,1,0,.324,1.265,1.265,0,0,1-.2.3c-.1.1-.2.233-.291.313s-.2.185-.1.373a5.576,5.576,0,0,0,.987,1.316,5.084,5.084,0,0,0,1.461.971c.183.1.292.088.405-.031s.485-.522.616-.7.254-.145.422-.077,1.065.549,1.248.648.3.15.348.229A1.53,1.53,0,0,1,13.429,12.226Z" transform="translate(-2.25 -2.25)" fill="#fff"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a :href="'https://www.linkedin.com/shareArticle?mini=true&url=' +url" target="_blank" rel="noreferrer nofollow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.843" height="14.843" viewBox="0 0 14.843 14.843">
                                            <path data-name="Icon awesome-linkedin-in" d="M3.322,14.843H.245V4.934H3.322ZM1.782,3.582a1.791,1.791,0,1,1,1.782-1.8A1.8,1.8,0,0,1,1.782,3.582ZM14.84,14.843H11.769V10.019c0-1.15-.023-2.624-1.6-2.624-1.6,0-1.845,1.249-1.845,2.541v4.907H5.25V4.934H8.2V6.285h.043a3.233,3.233,0,0,1,2.912-1.6c3.114,0,3.687,2.051,3.687,4.715v5.444Z" transform="translate(0 -0.001)" fill="#fff"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a :href="'http://twitter.com/share?text=' + title[$i18n.locale] + '&url=' +url" target="_blank" rel="noreferrer nofollow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.056" height="13.14" viewBox="0 0 16.056 13.14">
                                            <path data-name="Path 42070" d="M16.349,5.223a.34.34,0,0,0,.118-.34.347.347,0,0,0-.257-.264l-.548-.139a.34.34,0,0,1-.229-.187.375.375,0,0,1,0-.3l.305-.618a.34.34,0,0,0-.042-.368.361.361,0,0,0-.361-.118l-1.388.389a.326.326,0,0,1-.305-.056A3.47,3.47,0,0,0,8.09,6v.25a.167.167,0,0,1-.153.174c-1.95.229-3.817-.791-5.83-3.082a.347.347,0,0,0-.354-.1.375.375,0,0,0-.2.312,5.3,5.3,0,0,0,.319,3.415.174.174,0,0,1-.18.25L.913,7.021a.34.34,0,0,0-.3.1.34.34,0,0,0-.1.305,3.582,3.582,0,0,0,1.645,2.644.18.18,0,0,1,.1.16.167.167,0,0,1-.1.153l-.368.146a.368.368,0,0,0-.194.2.354.354,0,0,0,0,.278,3.026,3.026,0,0,0,2.221,1.721.18.18,0,0,1,.118.167.174.174,0,0,1-.118.16,7.392,7.392,0,0,1-2.978.576.361.361,0,0,0-.416.278A.354.354,0,0,0,.7,14.33a13.882,13.882,0,0,0,5.671,1.34,8.669,8.669,0,0,0,4.859-1.388,8.641,8.641,0,0,0,3.852-7.26V6.438a.4.4,0,0,1,.125-.264Z" transform="translate(-0.42 -2.53)" fill="#fff"></path>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Breadcrumb from "@/components/shared/Breadcrumb.vue";
    export default {
        name: "InnerHero",
        components: {
            Breadcrumb
        },
        props: ['title', 'links'],
        computed: {
            url() {
                return location.href;
            },
        },

    }
</script>