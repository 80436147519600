import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_SEARCH_LIST(state) {
        state.searchList.PageNumber = 0;
        state.searchList.data = [];
    },
    UPDATE_SEARCH_LIST(state, payload) {
        state.searchList.PageNumber = payload.PageNumber;
        state.searchList.totalRecord = payload.Total;


        payload.Data.map(item => functions.addDate(item, "Date"));
        return state.searchList.data =
            payload.PageNumber == 1
            ? (state.searchList.data = payload.Data)
            : state.searchList.data.concat(payload.Data);
    }
}