import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_IMAGES_GALLERY_LIST(state) {
        state.imagesGalleryList.PageNumber = 0;
        state.foldersList.data = [];
    },
    UPDATE_IMAGES_GALLERY_LIST(state, payload) {
        state.imagesGalleryList.PageNumber = payload.PageNumber;
        state.imagesGalleryList.totalRecord = payload.Total;
        return state.imagesGalleryList.data =
            payload.PageNumber == 1
            ? (state.imagesGalleryList.data = payload.Data)
            : state.imagesGalleryList.data.concat(payload.Data);
    },
    RESET_IMAGES_GALLERY_DETAILS(state) {
        /*state.foldersList.PageNumber = 0;*/
        state.imagesGalleryDetails.AttachmentAr = [];
        state.imagesGalleryDetails.AttachmentEn = [];
    },
    GET_IMAGES_GALLERY_DETAILS(state, payload) {
      
        state.imagesGalleryDetails = payload.data;
    }
}