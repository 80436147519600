<template>
    <div>
        <Header></Header>
        <slot></slot>   
        <!-- <RatingSection :current-path="currentPath"></RatingSection> -->
        <Footer v-if="$route.name != 'Home'"></Footer>
    </div>
</template>

<script>
    import Header from '@/components/shared/Header.vue';
    import Footer from '@/components/shared/Footer.vue';
  import RatingSection from '@/components/RatingSection.vue';
    export default {
        name: "master_layout",
        components: {
            Header,
            Footer,
            RatingSection
        },
        data(){
          return{

              currentPath:''
          }

        },
         created() {  
            this.currentPath = window.location.pathname;
            console.log("Current path using window object:", window.location.pathname); // Using window.location
        }
    };
</script>