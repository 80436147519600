export default () => ({
    eventsList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    eventDetails: null,
    isLoading: false,
});
