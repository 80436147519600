import axios from "axios";

export default {
    getServicesList({ commit, state }) {
        if (!state.servicesList.length)
            axios
                .post(process.env.VUE_APP_APIEndPoint + 'Services/PublicGetAll', {
                    "pageNumber": 0,
                    "pageSize": 0,
                })
                .then(response => {
                    commit("GET_SERVICES_LIST", {
                        data: response.data.Data,
                    })
                }); 
    },
    getServiceDetails({ commit }, id) {
        axios
            .get(process.env.VUE_APP_APIEndPoint + 'Services/GetServicesDetailsById/' + id)
            .then(response => {
                commit("GET_SERVICE_DETAILS", {
                    data: response.data.Data,
                })
            });
    }
}