export default () => ({
    newsList: {
        data: [],
        PageNumber: 0,
        totalRecord: 0,
        PageSize: 20
    },
    newsDetails: null,
    isLoading: false,
});
