import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_EVENTS_LIST(state) {
        state.eventsList.PageNumber = 0;
        state.eventsList.data = [];
    },
    UPDATE_EVENTS_LIST(state, payload) {
        state.eventsList.PageNumber = payload.PageNumber;
        state.eventsList.totalRecord = payload.Total;


        payload.Data.map(item => {
            item["StartDateGregorianAr"] = functions.formatDateAr(item["StartDate"]);
            item["StartDateGregorianEn"] = functions.formatDateEn(item["StartDate"]);
            item["EndDateGregorianAr"] = functions.formatDateAr(item["EndDate"]);
            item["EndDateGregorianEn"] = functions.formatDateEn(item["EndDate"]);
            return item;
        });
        return state.eventsList.data =
            payload.PageNumber == 1
            ? (state.eventsList.data = payload.Data)
            : state.eventsList.data.concat(payload.Data);
    },
    GET_EVENT_DETAILS(state, payload) {
        if (payload.data) {
            payload.data["StartDateGregorianAr"] = functions.formatDateAr(payload.data["StartDate"]);
            payload.data["StartDateGregorianEn"] = functions.formatDateEn(payload.data["StartDate"]);
            payload.data["EndDateGregorianAr"] = functions.formatDateAr(payload.data["EndDate"]);
            payload.data["EndDateGregorianEn"] = functions.formatDateEn(payload.data["EndDate"]);
        }
        state.eventDetails = payload.data;
    }
}