<template>
    <div>
        <vue-cookie-accept-decline :ref="'app'"
                                   :elementId="'app'"
                                   :debug="false"
                                   :position="'bottom'"
                                   :type="'bar'"
                                   :disableDecline="true"
                                   :transitionName="'slideFromBottom'"
                                   @status="cookieStatus"
                                   @clicked-accept="cookieClickedAccept"
                                   @clicked-decline="cookieClickedDecline"
                                   @removedCookie="cookieRemovedCookie">
            <!-- Optional -->
            <div slot="message" class="message">
                <span>
                    {{$t('privacyAndCookies')}}
                </span>
                <p>
                    {{$t('cookiesMessage')}}
                    <router-link :to="{name: 'Privacy'}">{{$t('termsAndConditionsAndPolicies')}}</router-link>
                </p>
            </div>

            <!-- Optional -->
            <div slot="declineContent">{{$t('decline')}}</div>

            <!-- Optional -->
            <div slot="acceptContent">{{$t('accept')}}</div>
        </vue-cookie-accept-decline>
    </div>
</template>

<script>
    import { loadGoogleAnalytics } from '@/scripts/googleAnalytics';

    export default {
        mixins: [],
        name: "CookiesAcceptDecline",
        data() {
            return {
                status: null,
            };
        },
        methods: {
            cookieStatus(status) {
                this.status = status;
            },
            cookieClickedAccept() {
                loadGoogleAnalytics();
                this.status = "accept";
            },
            cookieClickedDecline() {
                this.status = "decline";
            },
            cookieRemovedCookie() {
                this.status = null;
                this.$refs.myPanel1.init();
            },

            removeCookie() {
                this.$refs.myPanel1.removeCookie();
            }
        },
        computed: {
            statusText() {
                return this.status || "No cookie set";
            }
        }
    }
</script>
