import axios from "axios";

export default {
    getHeroSliders({ state, commit }) {
        if (!state.hero.items.length) {
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'Banner/PublicGetAll')
                .then(response => {
                    commit("UPDATE_HERO_SLIDERS", {
                        //data: response.data.Data.filter(item => item.PhotoAttachment),
                        data: response.data.Data,
                    })
                });
        }
    },
    getLatestNews({ state, commit }) {
        if (!state.latestNewsList.length) {
            axios
                .get(process.env.VUE_APP_APIEndPoint + "News/GetLatest")
                .then((response) => {
                    commit("UPDATE_LATEST_NEWS", {
                        data: response.data.Data,
                    })
                })
        }
    },
    getLatestStatistics({ state, commit }) {
        if (!state.latestStatisticsList.length) {
            axios
                .get(process.env.VUE_APP_APIEndPoint + "Achievement/GetLatest")
                .then((response) => {
                    commit("UPDATE_LATEST_STATISTICS", {
                        data: response.data.Data,
                    })
                })
        }
    },
    getLatestInvestments({ state, commit }) {
        if (!state.investments.length) {
            axios
                .get(process.env.VUE_APP_APIEndPoint + "Investment/GetLatest")
                .then((response) => {
                    commit("UPDATE_INVESTMENT", {
                        data: response.data.Data,
                    })
                })
        }
    },
    getLatestTenderAnnouncement({ state, commit }) {
        if (!state.tenderAnnouncements.length) {
            axios
                .get(process.env.VUE_APP_APIEndPoint + "TenderAnnouncement/GetLatest")
                .then((response) => {
                    commit("UPDATE_TRENDER_ANNOUNCEMENT", {
                        data: response.data.Data,
                    })
                })
        }
    },
    getServices({ state, commit }) {
        if (!state.services.length) {
            axios
                .get(process.env.VUE_APP_APIEndPoint + "Services/GetLatest")
                .then((response) => {
                    commit("UPDATE_SERVICES", {
                        data: response.data.Data,
                    })
                })
        }
    },
    getRealEstates({ state, commit }) {
        if (!state.realEstates.length) {
            axios
                .get(process.env.VUE_APP_APIEndPoint + "RealEstate/PublicGetAll")
                .then((response) => {
                    commit("UPDATE_REALESTATES", {
                        data: response.data.Data,
                    })
                })
        }
    }
}