import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    GET_HASHTAGES(state, payload) {
       
        state.HashTages = payload.data;
    },
     GET_HASHTAGES_LIST(state, payload) {

         state.resultList = payload.data;
    }
}