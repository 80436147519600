import axios from "axios";

export default {
    
    getHashTages({ state, commit }) {
        if (!state.HashTages.length)
        axios
            .get(process.env.VUE_APP_APIEndPoint + 'HashTages/GetAllHashTages/' )
            .then(response => {
                commit("GET_HASHTAGES", {
                    data: response.data.Data,
                })
            });
    },

    getResultList({ state, commit }, obj) {
        var url = state.HasTagesMeta.find(a=>a.id==obj.moduleType).apiUrl;
            axios
                .get(process.env.VUE_APP_APIEndPoint + url + obj.hashTages)
                .then(response => {
                    commit("GET_HASHTAGES_LIST", {
                        data: response.data,
                    })
                });
    }
}