import functions from '@/functions.js'

export default {
    UPDATE_LOADING_STATE(state, loadingState) {
        return state.isLoading = loadingState;
    },
    RESET_BRANCHES_LIST(state) {
        state.branchesList.PageNumber = 0;
        state.branchesList.data = [];
    },
    UPDATE_BRANCHES_LIST(state, payload) {
        state.branchesList.PageNumber = payload.PageNumber;
        state.branchesList.totalRecord = payload.Total;

        return state.branchesList.data =
            payload.PageNumber == 1
            ? (state.branchesList.data = payload.Data)
            : state.branchesList.data.concat(payload.Data);
    },
    GET_CONTACTS(state, payload) {
        state.contacts = payload.data;
    }
}