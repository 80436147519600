export default {
    GET_ABOUT_AUTHORITY(state, payload) {
        state.aboutAuthority = payload.data;
    },
    GET_BOARD_OF_DIRECTORS(state, payload) {
        state.boardOfDirectors = payload.data;
    },
    GET_ORGANIZATIONAL_CHART(state, payload) {
        state.organizationalChart = payload.data;
    },
    GET_GOVERNOR_OF_AUTHORITY(state, payload) {
        state.governorOfAuthority = payload.data;
    },
    GET_High_LEADERSHIPS(state, payload) {
        state.HighLeaderships = payload.data;
    },
}