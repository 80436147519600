<template>
    <footer class="footer">
        <div class="container">
            <RatingSection :current-path="currentPath"></RatingSection>
            <div class="footer__logo"><img src="@/assets/images/logo.svg" alt="logo"></div>

            <div class="footer__social">
                <h6>{{ $t("SocialMediaLinks") }}</h6>
                <div class="footer__social__links">
                    <a href="https://www.instagram.com/spga.ksa/" target="_blank" rel="nofollow">
                        <svg width="15.759" height="15.755" viewBox="0 0 15.759 15.755">
                            <path d="M7.876,6.076a4.039,4.039,0,1,0,4.039,4.039A4.033,4.033,0,0,0,7.876,6.076Zm0,6.666A2.626,2.626,0,1,1,10.5,10.115a2.631,2.631,0,0,1-2.626,2.626Zm5.147-6.831a.942.942,0,1,1-.942-.942A.94.94,0,0,1,13.023,5.911Zm2.675.956a4.663,4.663,0,0,0-1.273-3.3,4.693,4.693,0,0,0-3.3-1.273c-1.3-.074-5.2-.074-6.5,0a4.687,4.687,0,0,0-3.3,1.269A4.678,4.678,0,0,0,.05,6.863c-.074,1.3-.074,5.2,0,6.5a4.663,4.663,0,0,0,1.273,3.3,4.7,4.7,0,0,0,3.3,1.273c1.3.074,5.2.074,6.5,0a4.663,4.663,0,0,0,3.3-1.273,4.693,4.693,0,0,0,1.273-3.3c.074-1.3.074-5.2,0-6.5Zm-1.68,7.892a2.659,2.659,0,0,1-1.5,1.5,17.363,17.363,0,0,1-4.644.316,17.5,17.5,0,0,1-4.644-.316,2.659,2.659,0,0,1-1.5-1.5,17.363,17.363,0,0,1-.316-4.644,17.5,17.5,0,0,1,.316-4.644,2.659,2.659,0,0,1,1.5-1.5,17.363,17.363,0,0,1,4.644-.316,17.5,17.5,0,0,1,4.644.316,2.659,2.659,0,0,1,1.5,1.5,17.363,17.363,0,0,1,.316,4.644C14.334,11.261,14.429,13.726,14.017,14.759Z" transform="translate(0.005 -2.238)" fill="#fff"></path>
                        </svg>
                    </a><a href="https://www.youtube.com/c/SPGAKSA" target="_blank" rel="nofollow">
                        <svg width="17.994" height="12.576" viewBox="0 0 17.994 12.576">
                            <path d="M17.626,7.366A2.255,2.255,0,0,0,16.06,5.8a54.039,54.039,0,0,0-7.047-.376A52.832,52.832,0,0,0,1.965,5.8,2.255,2.255,0,0,0,.4,7.366,23.437,23.437,0,0,0,.007,11.72,23.437,23.437,0,0,0,.4,16.057a2.255,2.255,0,0,0,1.566,1.566A53.92,53.92,0,0,0,9.012,18a54.049,54.049,0,0,0,7.047-.377,2.255,2.255,0,0,0,1.566-1.566A23.436,23.436,0,0,0,18,11.72a23.436,23.436,0,0,0-.375-4.354ZM7.211,14.413v-5.4l4.7,2.7Z" transform="translate(-0.007 -5.424)" fill="#fff"></path>
                        </svg>
                    </a><a href="https://x.com/SpgaKsa" target="_blank" rel="nofollow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.995" viewBox="0 0 16 12.995">
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" transform="translate(0 -1.503)" fill="#fff"></path>
                        </svg>
                    </a><a href="https://www.linkedin.com/company/spga-ksa/mycompany/" target="_blank" rel="nofollow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <path d="M3.134,14H.231V4.653h2.9ZM1.681,3.378a1.689,1.689,0,1,1,1.681-1.7A1.7,1.7,0,0,1,1.681,3.378ZM14,14H11.1V9.45c0-1.084-.022-2.475-1.509-2.475-1.509,0-1.74,1.178-1.74,2.4V14h-2.9V4.653H7.736V5.928h.041a3.05,3.05,0,0,1,2.746-1.509C13.46,4.419,14,6.353,14,8.866V14Z" transform="translate(0 0)" fill="#fff"></path>
                        </svg>
                    </a><a href="https://www.facebook.com/spgaksa" target="_blank" rel="nofollow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8.569" height="16" viewBox="0 0 8.569 16">
                            <path d="M8.723,9l.444-2.9H6.389V4.225A1.448,1.448,0,0,1,8.022,2.661H9.285V.2A15.4,15.4,0,0,0,7.043,0,3.535,3.535,0,0,0,3.259,3.9V6.1H.715V9H3.259v7h3.13V9Z" transform="translate(-0.715)" fill="#fff"></path>
                        </svg>
                    </a>
                </div>
            </div>
            <ul class="footer__links">
                <li><router-link :to="{name: 'OpenData'}">{{$t("OpenData")}}</router-link></li>
                <li><router-link :to="{name: 'RelevantAuthorities'}">{{$t("RelevantAuthorities")}}</router-link></li>
                <li><router-link :to="{name: 'Recruitment'}">{{$t("Hiring")}}</router-link></li>
                <!--<li><a href="#">الاستثمارات</a></li>-->
                <!--<li><a href="#">اتصل بنا</a></li>-->
            </ul>
            <ul class="footer__useful-links">
                <!--<li><router-link :to="{name: 'GreetingCard'}">بطاقة التهنئة</router-link></li>-->
                <!--<li><a href="#">البوابة الداخلية</a></li>-->
                <li><router-link :to="{name: 'SiteMap'}">{{$t("SiteMap")}}</router-link></li>
                <!--<li><router-link :to="{name: 'Faq'}">الاسئلة الشائعة</router-link></li>-->
                <li><router-link :to="{name: 'Privacy'}">{{ $t("PrivacyPolicy") }}</router-link></li>
            </ul>
            <p class="copy-right">
                {{$t("CopyRight")}}
                &copy; {{new Date().getFullYear()}}
            </p>
        </div>
    </footer>
</template>

<script>
    import SubscribeNewsletter from '@/components/shared/SubscribeNewsletter.vue'
     import RatingSection from '@/components/RatingSection.vue';
    export default {
        name: "Footer",
        components: {
            SubscribeNewsletter,
             RatingSection
        },
         data(){
          return{
            
               currentPath: this.$route.path
          }

        },
         watch: {
            // Watch for route changes and update the currentPath accordingly
            $route(to, from) {
                this.currentPath = to.path;

                   console.log('Updated currentPath:', this.currentPath);
            }
        },
         created() {  
            this.currentPath = window.location.pathname;
            console.log("Current path using window object:", window.location.pathname); // Using window.location
        }
    };
</script>

<style lang="scss" scoped>
    .footer {
        background: linear-gradient(180deg, rgba(82,116,66,1) 0%, rgba(32,80,86,1) 100%);
        text-align: center;
        z-index: 99;
        color: #fff;
        overflow: hidden;
        padding: 4rem 0 2rem;
        position: relative;

        @include respond-below(sm) {
            padding: 2rem 0 1rem;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-image: url(@/assets/images/footer-bg.svg);
            background-position: bottom;
            background-repeat: repeat-x;
            opacity: 0.5;
            z-index: -1;
            mix-blend-mode: overlay;
            animation-name: moving;
            animation-duration: 60s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            @include respond-below(sm) {
                // background-size: contain;
            }
        }

        &__logo {
            //margin-top: 1.4rem;
            @include respond-below(xxl) {
                img {
                    max-height: 80px;
                }
            }

            @include respond-below(xl) {
                img {
                    width: 280px;
                }
            }

            @include respond-below(sm) {
                img {
                    width: 200px;
                }
            }
        }

        &__contact {
            margin-top: 3.5rem;
            text-align: center;

            h5 {
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            p {
                font-size: .8rem;
                opacity: 0.7;
                margin-bottom: 2rem;
            }

            @include respond-below(xxl) {
                margin-top: 2rem;

                p {
                    margin-bottom: 1rem;
                }
            }

            @include respond-below(sm) {
                margin-top: .8rem;

                h5 {
                    font-size: 1rem;
                }

                p {
                    font-size: .6rem;
                }
            }

            &__info {
                position: relative;
                max-width: 500px;
                height: 60px;
                margin-inline: auto;

                &::after {
                    content: '';
                    @include center('v');
                    width: 20px;
                    height: 15px;
                    inset-inline-start: 10px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.998' height='15.998' viewBox='0 0 19.998 15.998'%3E%3Cpath d='M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z' transform='translate(-3 -6)' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
                }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;
                    border: 1px solid #fff;
                    padding: 1rem;
                    border-radius: 90px;
                    color: #fff;
                    text-indent: 1.25rem;
                    font-size: 0.875rem;
                    background: rgba(#fff, 0.07);

                    &::placeholder {
                        font-size: 0.875rem;
                        color: #fff;
                        text-indent: 1.25rem;
                    }
                }

                button {
                    @include center('v');
                    inset-inline-end: 10px;
                    font-size: 14px;
                    font-weight: 600;
                    background: #fff;
                    color: #105857;
                    padding: 10px 20px;
                    border: 1px solid #fff;
                    border-radius: 90px;
                }
                // &__box {
                //   background: rgba(#FFF, 0.05);
                //   border-radius: 5px;
                //   padding: 25px 10px;
                //   min-width: 177px;
                //   transition: background ease-in-out 0.25s;
                //   backdrop-filter: blur(3.5px) brightness(1.1);
                //   color: #fff !important;
                //   svg {
                //     height: 20px;
                //     width: auto;
                //     opacity: 0.8;
                //     transition: opacity ease-in-out .25s;
                //   }
                //   p {
                //     margin-top: 0.3rem;
                //     margin-bottom: .85rem;
                //     opacity: 0.8;
                //     transition: opacity ease-in-out .25s;
                //   }
                //   span {
                //     font-size: 1rem;
                //     display: block;
                //     line-height: 1;
                //     opacity: 0.8;
                //     transition: opacity ease-in-out .25s;
                //   }
                //   &:hover {
                //     background: rgba(#FFF, 0.2);
                //     p {
                //       opacity: 1;
                //     }
                //     svg {
                //       opacity: 1;
                //     }
                //     span {
                //       opacity: 1;
                //     }
                //   }
                //   @include respond-below(xxl) {
                //     padding: 9px 5px;
                //     min-width: 120px;
                //     svg {
                //       height: 17px;
                //     }
                //     p {
                //       margin-top: 0.2rem;
                //       margin-bottom: .55rem;
                //     }
                //     span {
                //       font-size: 0.8rem;
                //     }
                //   }
                // }
                @include respond-below(sm) {
                    flex-wrap: wrap;
                    gap: 1rem;

                    .mail-box, .phone-box {
                        padding: 15px 6px;

                        svg {
                            width: 22px;
                        }

                        span {
                            font-size: .9rem;
                        }
                    }
                }
            }
        }

        &__social {
            margin-top: 3.25rem;

            h6 {
                font-size: .875rem;
                margin-bottom: .75rem;
            }

            &__links {
                display: flex;
                justify-content: center;
                gap: 0.5rem;

                a {
                    border: 1px solid #fff;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    transition: all ease-in-out .25s;
                    opacity: .6;

                    path {
                        transition: fill ease-in-out .25s;
                    }

                    &:hover {
                        opacity: 1;
                        background-color: #fff;

                        path {
                            fill: $green-dark
                        }
                    }
                }
            }

            @include respond-below(xxl) {
                margin-top: 2rem;
            }

            
        }

        &__links {
            display: flex;
            justify-content: center;
            gap: 2rem;
            margin-top: 3.5rem;

            @include respond-below(xxl) {
                margin-top: 2rem;
            }

            @include respond-below(xl) {
                gap: 1.5rem;
                flex-wrap: wrap;
            }

            @include respond-below(lg) {
                gap: .7rem;
                flex-wrap: wrap;
                margin-top: 3rem;
            }

            @include respond-below(sm) {
                margin-top: 2rem;
                flex-direction: column;
            }

            li {
                position: relative;

                a {
                    color: #fff;
                    transition: opacity ease-in-out .25s;
                    font-weight: 600;
                    display: inline-flex;
                    align-items: center;
                    gap: .5rem;
                    opacity: .6;

                    @include respond-below(lg) {
                    }

                    &:hover {
                        opacity: 1;
                    }

                    &::before {
                        content: '';
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.83' height='15.883' viewBox='0 0 23.83 15.883'%3E%3Cpath d='M19.243,3.628c-.319-.175-.653-.359-1-.545L17.6,2.746l-.634-.329c-.326-.168-.663-.34-1.012-.512a.014.014,0,0,0-.011,0c-.341-.175-.693-.347-1.053-.519a.2.2,0,0,0-.041-.019c-.36-.179-.731-.355-1.109-.531s-.8-.37-1.209-.556c-.2-.09-.4-.179-.608-.273l-.011,0L11.91,0,11.9,0A80.255,80.255,0,0,0,0,6.391a69.169,69.169,0,0,1,11.915,9.491A69.171,69.171,0,0,1,23.83,6.391c-.467-.325-1.772-1.18-3.8-2.323C19.777,3.923,19.513,3.777,19.243,3.628Z' transform='translate(0)' fill='%23fff'/%3E%3C/svg%3E%0A");
                        top: 0;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 23px;
                        height: 15px;
                        transition: opacity ease-in-out .25s;
                    }

                    @include respond-below(sm) {
                        display: inline-block;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        &__useful-links {
            margin-top: 1.3rem;
            display: flex;
            justify-content: center;
            gap: 1.4rem;

            @include respond-below(xxl) {
                margin-top: 1rem;
            }

            @include respond-below(lg) {
                flex-wrap: wrap;
                gap: .7rem;
            }

            @include respond-below(sm) {
            }

            a {
                color: #fff;
                transition: opacity ease-in-out .25s;
                opacity: .6;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .copy-right {
            margin-bottom: 0;
            margin-top: 3.5rem;

            @include respond-below(xxl) {
                margin-top: 2rem;
            }

            @include respond-below(sm) {
                font-size: .85rem;
            }
        }
    }
</style>