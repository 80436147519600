import axios from "axios";

export default {
    resetBranchesList({ commit }) {
        commit("RESET_BRANCHES_LIST")
    },
    getBranchesList({ state, commit }, filterQuery) {
        let { PageNumber, PageSize, totalRecord } = state.branchesList;
        let query = {
            PageNumber: PageNumber + 1,
            PageSize,
            Filter: filterQuery
        };

        if (!state.isLoading && (!totalRecord || Math.ceil(totalRecord / PageSize) > PageNumber)) {
            commit("UPDATE_LOADING_STATE", true);
            axios
                .post(process.env.VUE_APP_APIEndPoint + "Branch/PublicGetAllWithPagination", query)
                .then(response => {
                    commit("UPDATE_BRANCHES_LIST", response.data)
                })
                .finally(() => { commit("UPDATE_LOADING_STATE", false) });
        }
    },
    getContacts({ commit, state }) {
        if (!Object.keys(state.contacts).length)
            axios
                .get(process.env.VUE_APP_APIEndPoint + 'contacts/PublicBranchGetOne/')
                .then(response => {
                    commit("GET_CONTACTS", {
                        data: response.data.Data,
                    })
                });
    }
}