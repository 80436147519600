<template>
    <div class="footer--newsletter">
        <div class="footer--newsletter-input-group">
            <input autocomplete="off" class="footer--newsletter-input" v-model="model.Email" type="email" :placeholder="$t('WriteEmail')" name="Email" v-validate="'required|email'">
            <button class="footer--newsletter-btn" type="button" @click="save">
                {{$t('Subscribe')}}
            </button>
        </div>
        <span class="form-error-text" v-if="showValidMessage && errors.has('Email')">{{errors.first('Email')}}</span>
    </div>
    <!--<div class="footer--col footer-subscribe-form">
        <h4 class="footer-heading mb-3">
            {{$t('SubscribeNewsletter')}}
        </h4>
        <p>
            {{$t('StayInformed')}}
        </p>
        <div class="form">-->
            <!--<div class="subscribe-input d-flex">-->
            <!--<div :class="[{'required-input': true, 'form-error': showValidMessage && errors.has('Email')}]">
                <div class="subscribe-input d-flex">
                    <input autocomplete="off" class="form-input rounded-pill" v-model="model.Email" type="email" :placeholder="$t('WriteEmail')" name="Email" v-validate="'required|email'">
                    <button class="button button--blue subscribe-btn" @click="save">
                        {{$t('Subscribe')}}
                    </button>
                </div>
                <span class="form-error-text" v-if="showValidMessage && errors.has('Email')">{{errors.first('Email')}}</span>
            </div>

        </div>

    </div>-->
</template>

<script>
    export default {
        name: "SubscribeNewsletter",
    data: function() {
     let self = this;
    return {
        dictionary: {
            en: {
                messages: {
                    required: function required(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('required');
                    },
                    email: function email() {
                        return 'Invalid Email Format';
                    },
                    max: function max(fieldName, numbers) {
                        return self.$t(fieldName) + self.$t('max') + " " + numbers + " " + self.$t('numbers');
                    }, min: function min(fieldName, numbers) {

                        return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                    },
                    alpha: function alpha_spaces(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('alphaSpaces');
                    },
                    //between: function between(fieldName, n1, n2) {
                    //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
                    //},
                    confirmed: function confirmed(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                    },
                    regex: function regex(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                    },

                    between: function between(fieldName, n) {
                        return self.$t(fieldName) + ' ' + ' Should be Between ' + n[0] + ' and ' + n[1];
                    },
                    numeric: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('should be Number');
                    },
                    verify_password: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                    },
                    mobileReg: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('Number');
                    }
                }
            },
            ar: {
                messages: {
                    required: function required(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('required');
                    },
                    email: function email() {
                        return 'تنسيق بريد إلكتروني غير صالح';
                    },
                    max: function max(fieldName, numbers) {
                        return self.$t(fieldName) + self.$t('max') + numbers + self.$t('numbers');
                    }, min: function min(fieldName, numbers) {

                        return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                    },
                    alpha: function alpha_spaces(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('shouldBeCharOnly');
                    },
                  
                    confirmed: function confirmed(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                    },
                    regex: function regex(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                    },
                    between: function between(fieldName, n) {
                        return self.$t(fieldName) + ' ' + 'يجب ان يكون بين ' + n[0] + 'و' + n[1];
                    },
                    numeric: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('should be Number');
                    },
                    verify_password: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                    },
                    mobileReg: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('Number');
                    }
                }
            }

        },
        model: {},
        showValidMessage: false,
        successmsg: self.$t('SubscriptionSucces'),
        Alreadymsg: self.$t('SubscriptionAlready'),
        warnmesg: self.$t('warnmsgEmail'),
    };
  },
  methods: {
      notify: function (message, fn = "error") {
          let self = this;
          self.$snotify.clear();
          if (fn == "success") {
              self.$snotify.success(message, {
                  timeout: 3000,
                  showProgressBar: true,
                  //closeOnClick: false,
                  pauseOnHover: true
              });
          }
          else if (fn == "warning") {
              self.$snotify.warning(message, {
                  timeout: 3000,
                  showProgressBar: true,
                  //closeOnClick: false,
                  pauseOnHover: true
              });
          }
          else {
              self.$snotify.html(message, {
                  type: "error",
                  timeout: 3000,
                  showProgressBar: true,
                  //closeOnClick: false,
                  pauseOnHover: true
              });
          }
      },
      ClearModel: function () {
          var self = this;
          self.model = {};
          self.showValidMessage = false;
      },
      Submit: function () {
          var self = this;
          self.axios
              .post(process.env.VUE_APP_APIEndPoint + "NewsLetterSubscription/Add", self.model)
              .then(function (res) {
                  if (res.data.IsSuccess == true) {
                      self.notify(self.successmsg, "success");
                      self.ClearModel();
                  }
                  else {
                      self.notify(self.Alreadymsg, "error");
                      self.ClearModel();
                  }
                  
              });
      },
      save: function () {
          let self = this;
          self.showValidMessage = true;
          self.$validator.validate().then(function (result) {
              if (!result) { ////not valid
                  let detail = $('.form-error').first()[0];
                  if (detail) {
                      //detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                      self.notify(self.warnmesg, "warning");

                  }
                  return;
              }
              $('.form-error').removeClass("form-error");
              self.Submit();


          });
      },
  },

  mounted() {
      let self = this;
      this.$validator.localize(`${self.$i18n.locale}`, self.$i18n.locale == 'en' ? this.dictionary.en : this.dictionary.ar);
   
  },
  updated() {
  },
};
</script>


<style scoped>
    .footer--newsletter {
        display: block;
        flex: unset;
    }
    .form-error-text {
        display: block;
        color: #e62b2b;
        font-size: 12px;
        margin-top: 3px;
    }
</style>